@keyframes sheet-flash {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.sheet-flash {
  opacity: 0;
  z-index: 110000;
  position: fixed;
  left: 50vw;
  margin-top: 30px;
  animation-timing-function: ease;
  background: @color-white;
  border: 1px solid @color-black-dark;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(191, 191, 191, 0.8);
  color: @color-black-dark;
  grid-template-columns: min-content auto;
  padding: 10px 14px 10px 10px;
  display: none;
}

.sheet-flash__icon {
  width: 21px;
  height: 21px;
  background: @color-black-dark;
}

.sheet-flash__message {
  margin-left: 5px;
  font-size: @font-size-m;
}

.flash--success {
  display: grid;
  animation-name: sheet-flash;
}
