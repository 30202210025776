/*
 * Card colors
 * - color-1
 * - color-2
 * - color-3
 * - color-4
 *
 * Markup:
 * <div class=""></div>
 *
 * Styleguide: Card.Colors
 */
@yellowLight: #fce44d;
@yellowMid: #fad900;
@yellowDark: #e1c300;

@redActive: #f75e50;
@redLight: #e95b58;
@redMid: #e64845;
@redDark: #cf413e;

@tanLight: #fcaa1a;
@tanMid: #fca000;
@tanDark: #e29000;

@blueLight: #6ad0ee;
@blueMid: #2abbe7;
@blueDark: #26a8cf;

@greenLight: #cde06f;
@greenMid: #b8d231;
@greenDark: #a5bd2c;

@pinkLight: #f996da;
@pinkMid: #f668ca;
@pinkDark: #dd5db5;

@grayLight: #e6e6e6;
@grayBoxShadow: #d0d0d0;
@grayMid: #b3b3b3;
@graySirocco: #7a7f80;
@grayShadow: #606060;
@grayNevada: #5f6364;
@grayDark: #383a3d;

.card__plus-one--button .btn {
  background-color: rgba(255, 255, 255, 0.4);
  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.card.color-0 {
  .delete {
    color: @redDark;
    background-color: @redMid;
  }
}
.red,
.red .light {
  background-color: @redLight;
}
.red .mid,
.color-0 {
  background-color: @redMid;
}
.red .dark {
  background-color: @redDark;
}

.card.color-1 {
  .delete {
    color: @tanDark;
    background-color: @tanMid;
  }
}
.tan,
.tan .light {
  background-color: @tanLight;
}
.tan .mid,
.color-1 {
  background-color: @tanMid;
}
.tan .dark {
  background-color: @tanDark;
}

.card.color-2 {
  .delete {
    color: @yellowDark;
    background-color: @yellowMid;
  }
}
.yellow,
.yellow .light {
  background-color: @yellowLight;
}
.yellow .mid,
.color-2 {
  background-color: @yellowMid;
}
.yellow .dark {
  background-color: @yellowDark;
}

.card.color-3 {
  .delete {
    color: @greenDark;
    background-color: @greenMid;
  }
}
.green,
.green .light {
  background-color: @greenLight;
}
.green .mid,
.color-3 {
  background-color: @greenMid;
}
.green .dark {
  background-color: @greenDark;
}

.card.color-4 {
  .delete {
    color: @blueDark;
    background-color: @blueMid;
  }
}
.blue,
.blue .light {
  background-color: @blueLight;
}
.blue .mid,
.color-4 {
  background-color: @blueMid;
}
.blue .dark {
  background-color: @blueDark;
}

.card.color-5 {
  .delete {
    color: @pinkDark;
    background-color: @pinkMid;
  }
}
.pink,
.pink .light {
  background-color: @pinkLight;
}
.pink .mid,
.color-5 {
  background-color: @pinkMid;
}
.pink .pink {
  background-color: @pinkDark;
  color: @pinkLight;
}

.card.color-6 {
  .delete {
    color: @grayDark;
    background-color: @grayMid;
  }
}
.gray,
.gray .light {
  background-color: @grayLight;
}
.gray .mid,
.color-6 {
  background-color: @grayMid;
}
.gray .dark {
  background-color: @grayDark;
  color: @grayLight;
}

.i-like {
  background-color: @greenMid;
}
.i-wish {
  background-color: @tanMid;
}
.we-will {
  background-color: @blueMid;
}

.card.i-wish:hover,
.card.i-like:hover,
.card.we-will:hover {
  .card__colors {
    display: none;
  }
}
