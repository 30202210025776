/*
Card Group

A collection of stacked, grouped cards.

Markup:
<div class="group">
  <div class="background"></div>
  <div class="notice" style="display: none"></div>
  <input type="text" class="name" placeholder="Group Name" style="width: 140px;">
  <button class="add-card">+</button>
  <div id="564a30c9d99634000ac30e97" class="card color-2 draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); overflow: hidden;">
    <div class="header-bar">
      <span class="delete-btn">×</span>
      <span class="notice" style="display: none"></span>
    </div>
    <div class="content">
      <div class="viewable" style="height: auto; z-index: 31;">Card 1</div>
      <textarea class="editable" style="height: 16px; z-index: 29;"></textarea>
    </div>
    <div class="footer card__footer">
      <div class="card__authors"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
      <div class="card__colors">
        <span class="color card__color color-0"></span>
        <span class="color card__color color-1"></span>
        <span class="color card__color color-2"></span>
        <span class="color card__color color-3"></span>
        <span class="color card__color color-4"></span>
      </div>
      <div class="card__plus-one--button card__plus-one">
        <div class="card__plus-one-count">-1</div>
      </div>
      <div class="card__plus-one--static-text card__plus-one">
        <div class="card__plus-one-count">+1</div>
        <div class="tooltip-container flip">
          <div class="tooltip">
            <div class="tooltip__avatars"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="564a30c9d99634000ac30e97" class="card color-2 draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); overflow: hidden;">
    <div class="header-bar">
      <span class="delete-btn">×</span>
      <span class="notice" style="display: none"></span>
    </div>
    <div class="content">
      <div class="viewable" style="height: auto; z-index: 31;">Card 2</div>
      <textarea class="editable" style="height: 16px; z-index: 29;"></textarea>
    </div>
    <div class="footer card__footer">
      <div class="card__authors"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
      <div class="card__colors">
        <span class="color card__color color-0"></span>
        <span class="color card__color color-1"></span>
        <span class="color card__color color-2"></span>
        <span class="color card__color color-3"></span>
        <span class="color card__color color-4"></span>
      </div>
      <div class="card__plus-one--button card__plus-one">
        <div class="card__plus-one-count">-1</div>
      </div>
      <div class="card__plus-one--static-text card__plus-one">
        <div class="card__plus-one-count">+1</div>
        <div class="tooltip-container flip">
          <div class="tooltip">
            <div class="tooltip__avatars"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Presentation sugar for the styleguide -->
<br /><br /><br /><br /><br /><br /><br /><br /><br />

Styleguide: CardGroup
*/
.group {
  background: rgba(242, 242, 242, 0.8);
  border: 2px solid transparent;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  cursor: move;
  font-family: @font-family-sans;
  padding: 4px;
  position: absolute;
  user-select: none;

  &:hover {
    border-color: rgba(0, 0, 0, 0.25);
  }

  &.draggable {
    transition: top 0.5s, left 0.5s;

    &.being-dragged {
      cursor: not-allowed;

      .card {
        cursor: not-allowed;
      }
    }

    &.dragging {
      transition: none;
    }
  }

  .name-container {
    margin-bottom: 4px;
  }

  .card {
    box-shadow: none;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      background: rgba(127, 127, 127, 0.8);
      content: " ";
      display: none;
      height: 6px;
      margin-left: -2px;
      position: absolute;
      width: calc(100% + 4px);
    }

    &.hover-above {
      &:after {
        bottom: 100%;
        display: block;
        margin-bottom: 2px;
      }
    }

    &.hover-below {
      &:after {
        display: block;
        margin-top: 2px;
        top: 100%;
      }
    }
  }

  input {
    background-color: transparent;
    border: none;
    color: @graySirocco;
    font-size: 14px;
    font-weight: normal;
    line-height: unset;
    margin: 0;
    padding: 0;
    user-select: text;
    width: 250px;

    &:placeholder {
      color: @grayMid;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .add-card {
    background: none;
    border: none;
    color: @graySirocco;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      color: #a22;
    }
  }

  &:hover,
  &.dragging,
  &.stackable {
    background: rgba(242, 242, 242, 0.5);
    border-color: rgba(191, 191, 191, 0.5);

    input {
      color: #222;
    }

    button.add-card {
      color: #222;
      &:hover {
        color: #2a2;
      }
    }
  }

  &.single-card {
    background: none;
    border-color: transparent;
    box-shadow: none;

    &.dragging .card {
      box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.05);
    }

    .card {
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .name-container {
      margin-bottom: 0;
    }
  }
}

/*
Card Group: Being dragged

Markup:
<br /><br />
<div class="group">
  <div class="background"></div>
  <div class="notice">
    <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
    <span>Ernie Johnson</span>
  </div>
  <input type="text" class="name" placeholder="Group Name" style="width: 140px;">
  <button class="add-card">+</button>
  <div id="564a30c9d99634000ac30e97" class="card color-2 draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); overflow: hidden;">
    <div class="header-bar">
      <span class="delete-btn">×</span>
    </div>
    <div class="content">
      <div class="viewable" style="height: auto; z-index: 31;">Card 1</div>
      <textarea class="editable" style="height: 16px; z-index: 29;"></textarea>
    </div>
    <div class="footer card__footer">
      <div class="card__authors"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
      <div class="card__colors">
        <span class="color card__color color-0"></span>
        <span class="color card__color color-1"></span>
        <span class="color card__color color-2"></span>
        <span class="color card__color color-3"></span>
        <span class="color card__color color-4"></span>
      </div>
      <div class="card__plus-one--button card__plus-one">
        <div class="card__plus-one-count">-1</div>
      </div>
      <div class="card__plus-one--static-text card__plus-one">
        <div class="card__plus-one-count">+1</div>
        <div class="tooltip-container flip">
          <div class="tooltip">
            <div class="tooltip__avatars"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Presentation sugar for the styleguide -->
<br /><br /><br /><br /><br /><br /><br /><br /><br />

Styleguide: CardGroup.BeingDragged
*/

.group {
  > .notice {
    position: absolute;
    top: -30px;
    left: 0;
    .avatar {
      max-width: 22px;
      height: auto;
      margin-right: 0.125rem;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: @font-size-xxs;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.group__selected--self {
  &,
  &:hover,
  &.dragging {
    border: 2px solid black;
  }

  &.single-card {
    &,
    &:hover {
      border-color: transparent;
    }
  }
}
