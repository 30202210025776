.contact-us__text {
  flex: 2 1 auto;
  font-family: futura-pt-bold, sans-serif;
  font-size: 32px;
  color: @color-deep;
  width: 10em;
  margin-bottom: auto;
  margin-top: auto;

  @media @screen-m {
    margin-bottom: 0;
    margin-top: 0.2rem;
  }
}

.contact-us__button {
  margin-top: 1em;
  margin-left: 0;
  margin-bottom: auto;
  align-self: flex-start;

  @media @screen-m {
    margin-left: 4rem;
    margin-top: 0;
  }
}

.contact-us__banner {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 3rem 12%;
  background-color: @color-informational;

  @media @screen-m {
    padding: 6rem 12%;
    flex-direction: row;
  }
}

.stickies-copyright__banner {
  padding: 3rem 12% 5rem 12%;
  background-color: @color-bright;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;

  @media @screen-s {
    flex-direction: row;
    padding: 6rem 12%;
  }
}

.stickies-copyright__logo-and-copyright {
  font-family: futura-pt, sans-serif;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  order: 1;
  color: @color-white;

  @media @screen-s {
    order: 0;
  }
}

.stickies-copyright__nav-container {
  margin-top: 0;
  margin-bottom: 1.5rem;
  width: 24rem;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: 0;

  @media @screen-s {
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 0;
    order: 1;
  }
}
