/*
Icon Fonts

Fonts generated from our own custom icon font.

Markup:
<i class="icon-users"></i>
<i class="icon-stickies"></i>
<i class="icon-star"></i>
<i class="icon-link"></i>
<i class="icon-info"></i>
<i class="icon-draw"></i>
<i class="icon-draw-alt"></i>
<i class="icon-delete"></i>
<i class="icon-add"></i>
<i class="icon-edit"></i>
<i class="icon-download"></i>
<i class="icon-menu"></i>
<i class="icon-disguise"></i>
<i class="icon-bubble"></i>
<i class="icon-logout"></i>
<i class="icon-twitter"></i>
<i class="icon-bert"></i>
<i class="icon-ernie"></i>
<i class="icon-group"></i>
<i class="icon-stack"></i>
<i class="icon-trash"></i>
<i class="icon-folder"></i>
<i class="icon-restore"></i>
<i class="icon-crest"></i>
<i class="icon-facebook"></i>
<i class="icon-google"></i>
<i class="icon-help"></i>
<hr />
<i class="icon-users icon-size-big"></i>

Styleguide: Icons
*/
@font-face {
  font-family: "stickies";
  src: url("/fonts/stickies.eot");
  src: url("/fonts/stickies.eot?#iefix") format("embedded-opentype"), url("/fonts/stickies.ttf") format("truetype"),
    url("/fonts/stickies.woff") format("woff"), url("/fonts/stickies.svg#stickies") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: "stickies";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

/* Use the following CSS code if you want to have a class per icon */
[class*="icon-"] {
  vertical-align: middle;
  position: relative;
  &:before,
  &:after {
    font-family: "stickies";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.icon-size-big {
  font-size: @font-size-m;
}

.icon-users:before {
  content: "\e000";
}
.icon-stickies:before {
  content: "\e001";
}
.icon-star:before {
  content: "\e002";
}
.icon-link:before {
  content: "\e003";
}
.icon-info:before {
  content: "\e004";
}
.icon-draw:before {
  content: "\e005";
}
.icon-draw-alt:before {
  content: "\e006";
}
.icon-delete:before {
  content: "\e007";
}
.icon-x:before {
  content: "\e009";
}
.icon-add:before {
  content: "\e00a";
}
.icon-edit:before {
  content: "\e00b";
}
.icon-download:before {
  content: "\e601";
}
.icon-disguise:before {
  content: "\e600";
}
.icon-menu:before {
  content: "\e602";
}
.icon-bubble:before {
  content: "\e604";
}
.icon-logout:before {
  content: "\e605";
}
.icon-twitter:before {
  content: "\e606";
}
.icon-bert:before {
  content: "\e000";
}
.icon-ernie:before {
  content: "\e000";
}
.icon-group:before {
  content: "\e607";
}
.icon-stack:before {
  content: "\e608";
}
.icon-trash:before {
  content: "\e609";
}
.icon-folder:before {
  content: "\e60a";
}
.icon-crest:before {
  content: "\e60b";
}
.icon-cleanup:before {
  content: "\e001";
}
.icon-facebook:before {
  content: "\e60d";
}
.icon-google:before {
  content: "\ea88";
}
.icon-restore:before {
  content: "\e60f";
}
.icon-help:before {
  content: "\e900";
}
.icon-profile {
  background-image: url(/images/icons/profile-icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 9px;
  padding-top: 2px;
}
i[class*="png-icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.colored-png-icon(@icon) {
  .png-icon-@{icon},
  .png-icon-@{icon}--black {
    background-image: url("/images/icons/png/@{icon}_black.png");
  }

  .png-icon-@{icon}--white {
    background-image: url("/images/icons/png/@{icon}_white.png");
  }

  .png-icon-@{icon}--turquoise {
    background-image: url("/images/icons/png/@{icon}_turquoise.png");
  }
}

/*
Icon (PNGs)

PNG-style icons; can be turquoise, black, or white.

Markup:
<i class="png-icon-cupcake"></i>
<i class="png-icon-signout"></i>
<i class="png-icon-locked--black"></i>
<i class="png-icon-unlocked--black"></i>
<i class="png-icon-check--black"></i>
<div style="background-color:#000">
  <i class="png-icon-focus--white"></i>
  <i class="png-icon-incognito--white"></i>
  <i class="png-icon-moderator--white"></i>
  <i class="png-icon-check--white"></i>
</div>

Styleguide: Icons.IconPng
*/

.colored-png-icon(locked);
.colored-png-icon(unlocked);
.colored-png-icon(signout);
.colored-png-icon(check);
.colored-png-icon(focus);
.colored-png-icon(incognito);
.colored-png-icon(moderator);
.colored-png-icon(cupcake);
.colored-png-icon(loading);

.png-icon-business {
  background-image: url(/images/icons/png/business.png);
}

.png-icon-growth {
  background-image: url(/images/icons/png/growth.png);
}

.png-icon-startup {
  background-image: url(/images/icons/png/startup.png);
}

/*
Icons (SVGs)

SVG icons, built as masks.

Markup:
<div style="background-color:#000">
  <i class="svg-icon-incognito"></i>
  <i class="svg-icon-focus"></i>
  <i class="svg-icon-moderator"></i>
  <i class="svg-icon-sign-out"></i>
  <i class="svg-icon-check"></i>
  <i class="svg-icon-business"></i>
  <i class="svg-icon-growth"></i>
  <i class="svg-icon-startup"></i>
  <i class="svg-icon-locked"></i>
  <i class="svg-icon-unlocked"></i>
</div>

Styleguide: Icons.Svg
*/

i[class*="svg-icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: 100%;
  background-color: @color-white;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  transition: background-color 0.25s ease-in-out;
}

.svg-icon-incognito {
  mask-image: url(/images/icons/incognito.svg);
}

.svg-icon-focus {
  mask-image: url(/images/icons/focus.svg);
}

.svg-icon-moderator {
  mask-image: url(/images/icons/moderator.svg);
}

.svg-icon-sign-out {
  mask-image: url(/images/icons/sign-out.svg);
}

.svg-icon-check {
  mask-image: url(/images/icons/check.svg);
}

.svg-icon-business {
  mask-image: url(/images/icons/business.svg);
}

.svg-icon-growth {
  mask-image: url(/images/icons/growth.svg);
}

.svg-icon-startup {
  mask-image: url(/images/icons/startup.svg);
}

.svg-icon-locked {
  mask-image: url(/images/icons/locked.svg);
}

.svg-icon-unlocked {
  mask-image: url(/images/icons/unlocked.svg);
}

.svg-icon-check-circle {
  mask-image: url(/images/icons/check-circle.svg);
}
