/* Sample */

.dropdown-menu {
    border: 1px solid #ddd;
    background-color: white;
}

.dropdown-menu li {
    padding: 2px 10px;
    cursor: pointer;
    white-space: nowrap
}

.dropdown-menu .active {
    background-color: #428bca;
}

.dropdown-menu a {
  text-decoration: none;
  padding: 3px 20px;
}

.dropdown-menu .active a {
    color: #fff;
    text-decoration: none;
}


/* SHOULD not modify */

.dropdown-menu {
    list-style: none;
    padding: 5px 0;
    margin: 2px 0 0;
    border-radius: 4px;
}

.dropdown-menu a:hover {
    cursor: pointer;
}
