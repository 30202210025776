/*
Static Card

A card-like container to hold marketing copy + an icon + a CTA.

Markup:
<div class="static-card">
  <div class="static-card__icon">
    <i class="png-icon-cupcake"></i>
  </div>
  <div class="static-card__heading">
    Hang on.
  </div>
  <div class="static-card__copy">
    <p>We're evaluating interest in private boards and subscriptions. We're not quite ready to do either, but don't worry, we won't charge or save your card.</p>
    <p>For now, <strong>enjoy the free, full-featured version of Stickies.io. </strong>When we transition to subscriptions, we'll give you the first <strong>three months of your subscription free </strong>for being a champ.</p>
  </div>
  <div class="static-card__cta">
    <a href="/" class="button button--primary">Go to Stickies.io</a>
  </div>
</div>

Styleguide: StaticCard
*/

.static-card {
  margin-bottom: @vertical-rhythm;
}

.static-card__icon {
  font-size: @font-size-xl;
  margin-bottom: 2rem;
  text-align: center;
}

.static-card__heading {
  text-align: center;
  font-size: @font-size-l;
  font-weight: bold;
  margin-bottom: 1rem;
}

.static-card__copy {
  font-size: @font-size-l;
  margin-bottom: 2rem;

  p {
    line-height: 1.5em;
  }
}

.static-card__cta {
  text-align: center;
}
