/*
Sheet

The backdrop where our adventure begins.

.background-grid       - A dot grid for stickies
.background-leancanvas - A backdrop for Lean Canvas activities
.background-target     - Target bullseye graphic
.background-box        - Box/grid backdrop

Markup:
<div class="clearfix" style="height: 400px; width: 100%;">
  <div class="sheet {{modifier_class}}"></div>
</div>

Styleguide: Sheet
*/

.sheet {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;

  &.background-grid {
    background: transparent url("/images/grid.png") repeat 0 0;
    background-size: 10px 10px;
  }

  &.background-box {
    background: transparent url("/images/box.png") repeat 5px 0;
    background-size: 300px 300px;
  }

  &.background-target {
    background: transparent url("/images/target.jpg") no-repeat 75px 0;
  }

  &.background-leancanvas {
    background: transparent url("/images/leancanvas.png") no-repeat 0 -2px;
  }

  &.loading .group {
    visibility: hidden;
  }
}

.sheet--has-state {
  margin-top: 60px;
}
