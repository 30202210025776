#error-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bg {
    flex: 1;
    width: 100%;
    background-image: url("/images/backgrounds/404-not-found.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    @media @screen-s {
      box-shadow: 2px 2px 2px 0 fade(@color-black, 20%);
      padding: 0 10vw;
    }

    .words {
      margin: 0 auto 20px;
      position: relative;
      padding: 0 2rem;

      .explanation {
        color: #f75e50;
        font-size: @font-size-xl;
        font-weight: bold;
        line-height: 1.4;
      }

      .recommendation {
        font-size: @font-size-l;
        font-weight: 200;
        line-height: 1.3;
      }
    }

    .controls {
      width: 100%;
      margin: 1rem;
      padding: 0 2rem;
    }
  }
}
