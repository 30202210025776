/*
Card

The illustrious card of Stickies fame.

color-0 - Red
color-1 - Orange
color-2 - Yellow
color-3 - Green
color-4 - Blue
color-5 - Pink
color-6 - Gray

Markup:
<div class="card {{modifier_class}} draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); z-index: auto;">
  <div class="header-bar">
    <span class="delete-btn">×</span>
    <span class="notice" style="display: none"></span>
  </div>
  <div class="content">
    <div class="viewable" style="height: auto; z-index: 31;">Welcome to your virtual whiteboard!<br>
      Drag notes onto one another to create a group.
    </div>
    <textarea class="editable" style="height: 50px; z-index: 29;"></textarea>
  </div>
  <div class="card__footer">
    <div class="card__authors">
      <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
      <img class="avatar" src="/images/avatar-bert.jpg" title="Bert Bacharach">
    </div>
    <div class="card__colors">
      <span class="color card__color color-0"></span>
      <span class="color card__color color-1"></span>
      <span class="color card__color color-2"></span>
      <span class="color card__color color-3"></span>
      <span class="color card__color color-4"></span>
    </div>
    <div class="card__plus-one--button card__plus-one">
      <div class="card__plus-one-count">+1</div>
    </div>
    <div class="card__plus-one--static-text card__plus-one tooltip-parent" style="display: none;">
      <div class="count"></div>
      <span class="tooltip tooltip--right">
        <div class="tooltip__avatars">
        </div>
      </span>
    </div>
  </div>
</div>

Styleguide: Card
*/

/**
Card with a +1

Markup:
<div id="564a30c9d99634000ac30e97" class="card color-2 draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); overflow: hidden;">
  <div class="header-bar">
    <span class="delete-btn">×</span>
    <span class="notice" style="display: none"></span>
  </div>
  <div class="content">
    <div class="viewable" style="height: auto; z-index: 31;">🔏 </div>
    <textarea class="editable" style="height: 16px; z-index: 29;"></textarea>
  </div>
  <div class="footer card__footer">
    <div class="card__authors"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
    <div class="card__colors">
      <span class="color card__color color-0"></span>
      <span class="color card__color color-1"></span>
      <span class="color card__color color-2"></span>
      <span class="color card__color color-3"></span>
      <span class="color card__color color-4"></span>
    </div>
    <div class="card__plus-one--button card__plus-one">
      <div class="card__plus-one-count">-1</div>
    </div>
    <div class="card__plus-one--static-text card__plus-one tooltip-parent">
      <div class="card__plus-one-count">+1</div>
      <span class="tooltip tooltip--right">
        <div class="tooltip__avatars">
          <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
        </div>
      </span>
    </div>
  </div>
</div>

Styleguide: Card.PlusOne
*/
@card-width: 256px;
.card {
  font-family: @font-family-sans;
  position: relative;
  cursor: move;
  user-select: none;

  &.draggable {
    top: 0;
    left: 0;
    transition: top 0.5s, left 0.5s;

    &.being-dragged {
      cursor: not-allowed;
    }

    &.dragging,
    &.dropping {
      transition: none;
    }
  }

  .header-bar {
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 22px;

    .notice {
      position: relative;
      top: 0;
      float: right;
      height: 14px;
      padding: 4px;
      text-align: left;
      width: 85%;
      text-align: center;
      margin: 0 auto;

      .avatar {
        vertical-align: top;
        width: 14px;
        height: 14px;
      }

      span {
        margin-left: 5px;
        vertical-align: super;
        line-height: 14px;
        font-size: 12px;
      }
    }

    .title {
      float: left;
      padding: 4px;
      padding: 6px;
    }

    .delete-btn {
      font-size: @font-size-m * 1.1;
      float: right;
      padding-top: 0.15rem;
      padding-right: 0.4rem;
      line-height: 18px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.2);
      font-weight: 600;

      &:hover {
        color: darken(@color-red, 20);
      }
    }
  }

  .content {
    position: relative;
    background-color: inherit;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .viewable,
    .editable {
      overflow-y: visible;
      font-size: 14px;
      line-height: 16px;
      min-height: 24px;
      padding: 2px 4px 2px 4px;
      margin: 0px 4px;
      text-rendering: optimizeLegibility;
      border: none;
      background-color: inherit;
      outline: none;
      resize: none;
      width: (@card-width - 1);
      color: @color-gray-dark;
      user-select: text;
    }

    .viewable {
      position: relative;
      overflow-wrap: break-word;
      word-wrap: break-word;
      pointer-events: none;

      a {
        pointer-events: auto;

        &:hover {
          color: inherit;
        }
      }

      hr {
        margin: 10px 10px -10px 10px;
        border-top: 1px solid @color-gray-dark;
        border-bottom: 0;
      }
    }

    .editable {
      font-family: @font-family-sans;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.dragging {
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.05);
  }

  &.hilite {
  }

  &.lolite {
    opacity: 0.25;
  }

  &.color-4 {
    // blue
    .editable {
      &::selection {
        background: khaki;
      }

      &::-moz-selection {
        background: khaki;
      }
    }
  }
}
/**
Card state: editing

Markup:
<div class="card card--editing color-2" style="height: 100px">
  <div class="header-bar">
    <span class="delete-btn">×</span>
  </div>
  <div class="content">
    <div class="viewable" style="height: auto; z-index: 29;">
      Drag notes onto one another to create a group.
    </div>
    <textarea class="editable" style="height: 40px; z-index: 31;">
      Drag notes onto one another to create a group.
    </textarea>
  </div>
</div>

Styleguide: Card.StateEditing
*/

// Hide the editable area when not editing
.content:hover {
  .viewable {
    opacity: 1;
  }
  .editable {
    opacity: 0;
  }
}

.card--editing {
  box-shadow: 0 0 2px 3px #61aadb;

  // Hide the viewable area when editing
  .content:hover {
    .viewable {
      opacity: 0;
    }
    .editable {
      opacity: 1;
    }
  }
}

/*
Card state: Blurred

Markup:
<div class="sheet--state-blur">
<div class="card color-2 draggable" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); z-index: auto;">
  <div class="header-bar">
    <span class="delete-btn">×</span>
    <span class="notice" style="display: none"></span>
  </div>
  <div class="content">
    <div class="viewable" style="height: auto; z-index: 31;">Welcome to your virtual whiteboard!<br>
      Drag notes onto one another to create a group.
    </div>
    <textarea class="editable" style="height: 50px; z-index: 29;"></textarea>
  </div>
  <div class="card__footer">
    <div class="card__authors">
      <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
      <img class="avatar" src="/images/avatar-bert.jpg" title="Bert Bacharach">
    </div>
    <div class="card__colors">
      <span class="color card__color color-0"></span>
      <span class="color card__color color-1"></span>
      <span class="color card__color color-2"></span>
      <span class="color card__color color-3"></span>
      <span class="color card__color color-4"></span>
    </div>
    <div class="card__plus-one--button card__plus-one">
      <div class="card__plus-one-count">+1</div>
    </div>
    <div class="card__plus-one--static-text card__plus-one tooltip-parent" style="display: none;">
      <div class="count"></div>
      <span class="tooltip tooltip--right">
        <div class="tooltip__avatars">
        </div>
      </span>
    </div>
  </div>
</div>
</div>

Styleguide: Card.Blurred
*/

/* Blurred cards */
.sheet--state-blur {
  .card {
    .content * {
      text-shadow: 0 0 8px #000;
      color: rgba(255, 255, 255, 0);
      filter: "DXImageTransform.Microsoft.Blur(pixelradius=2)";
      zoom: 1;
      user-select: none;
    }

    &:not(.mine) {
      opacity: 0.35;
      background-color: @color-gray-light;
    }

    &.mine {
      opacity: 1;

      .content * {
        text-shadow: none;
        color: @color-gray-dark;
        filter: none;
        user-select: text;
      }
    }
  }
}

.card__footer {
  padding: 0.25rem 0.25rem;
  display: flex;
  align-items: center;
}

.card__authors {
  flex: 1.25;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.card__colors {
  flex: 1;
  display: flex;
  flex-shrink: 0;
}

.card__plus-one {
  flex-shrink: 0;
}

.card__plus-one-count {
  font-family: @font-family-mono;
  line-height: 1;
  padding-top: 0.3em;
  padding-right: 0.4em;
  padding-bottom: 0.2em;
  padding-left: 0.2rem;
  font-size: @font-size-xxs;
}

.card__color {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 12px;
  margin-left: 0.15rem;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card__plus-one--button {
  .card__plus-one-count {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
  }
}

.card__plus-one--static-text {
  &:hover,
  &.card__plus-one--hovered {
    .card__plus-one-count {
      background: fade(@color-gray, 80%);
      color: @color-white;
    }
  }
}

/**
Card Behavior: Revealing Actions on Hover

On hover, the card should reveal the action controls.

Markup:
<div id="564a30c9d99634000ac30e97" class="card card--reveal-actions-on-hover color-2 draggable mine" creator="5644e29a695bbc758025d965" style="touch-action: pan-y; -webkit-user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); overflow: visible;">
  <div class="header-bar">
    <span class="delete-btn">×</span>
    <span class="notice" style="display: none"></span>
  </div>
  <div class="content">
    <div class="viewable" style="height: auto; z-index: 31;">🔏 </div>
    <textarea class="editable" style="height: 16px; z-index: 29;"></textarea>
  </div>
  <div class="footer card__footer">
    <div class="card__authors"><img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson"></div>
    <div class="card__colors">
      <span class="color card__color color-0"></span>
      <span class="color card__color color-1"></span>
      <span class="color card__color color-2"></span>
      <span class="color card__color color-3"></span>
      <span class="color card__color color-4"></span>
    </div>
    <div class="card__plus-one--button card__plus-one">
      <div class="card__plus-one-count">-1</div>
    </div>
    <div class="card__plus-one--static-text card__plus-one tooltip-parent">
      <div class="card__plus-one-count">+1</div>
      <span class="tooltip tooltip--right">
        <div class="tooltip__avatars">
          <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
        </div>
      </span>
    </div>
  </div>
</div>

Styleguide: Card.RevealActionsOnHover
*/

.card--reveal-actions-on-hover {
  border: 2px solid transparent;

  .card__action {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.25);

    .card__action {
      opacity: 1;
    }
  }
}

.card__selected--self {
  &,
  &:hover {
    border: 2px solid black;
  }
}
