.container {
  max-width: 76rem;
  margin: 0 auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.container--full {
  max-width: inherit;
  padding-right: 0;
  padding-left: 0;
}
