/*
Collaborator List

Markup:
<div class="collaborator-list">
  <div class="collaborator-list__title">
    Collaborators (<span class="collaborator-list__count">4</span>)
  </div>
  <div class="collaborator-list__items">
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-ernie.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Ernie</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-bert.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Bert - selecting role</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role collaborator-role-selector__current-role--clicked">
          <div class="collaborator-role-selector__current-role-name">editor</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down menu--open">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-ernie.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Ernie</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--invitee">
      <img src="/images/icons/png/envelope.png" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">ernie@sesamestreet.com</span>
        <span class="collaborator-list__additional-actions">Resend invite</span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--invitee">
      <img src="/images/icons/png/envelope.png" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">ernie@sesamestreet.com - confirmation</span>
        <span class="collaborator-list__additional-actions collaborator-list__additional-actions--confirm">Sent!</span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
  </div>
</div>

Styleguide: CollaboratorList
*/

@collaborator-list-control-editable-icon-size: 24px;
@collaborator-list-control-padding-right: 0.2em;

.collaborator-list {
  display: block;
}

.collaborator-list__title {
  padding-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.collaborator-list__items {
  border-top: 1px solid @color-white-dark;
  border-bottom: 1px solid @color-white-dark;
  overflow: auto;
  height: 200px;
}

.collaborator-list__item {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  height: 40px;

  &:hover {
    border-bottom: 1px solid @color-white-dark;
  }
}

.collaborator-list__icon {
  width: 3rem;
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

.collaborator-list__main {
  flex-grow: 1;
}

.collaborator-list__additional-actions {
  display: none;
  color: @color-primary;
  padding-left: 0.5em;
}

.collaborator-list__item--invitee {
  .collaborator-list__icon {
    padding: 0.9rem 1rem;
  }

  .collaborator-list__name {
    color: @color-gray-light;
  }

  .collaborator-list__additional-actions {
    display: inline-block;
    opacity: 0;
    transition: opacity 300ms linear;
    cursor: pointer;
  }

  &:hover {
    .collaborator-list__additional-actions {
      opacity: 1;
    }
  }

  .collaborator-list__additional-actions--confirm {
    animation-name: flash;
    animation-duration: 4s;
    animation-timing-function: ease;
  }
}

.collaborator-role-selector {
  text-align: center;
  position: relative;
}

.collaborator-role-selector__current-role {
  width: 100%;
  display: inline-block;
  height: 36px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid @color-white-dark;
  }
  cursor: pointer;
}

.collaborator-role-selector__current-role--disabled {
  color: @color-gray-light;
  &:hover {
    border: none;
    padding-right: 1px;
  }

  .collaborator-role-selector__menu-toggle {
    visibility: hidden;
  }
}

.collaborator-role-selector__current-role-name {
  flex-grow: 1;

  &:first-letter {
    text-transform: uppercase;
  }
}

.collaborator-role-selector__current-role--clicked {
  border: 1px solid @color-white-dark;
  padding-right: 0px;
  &:hover {
    background-color: @color-white-dark;
  }
}

.collaborator-role-selector__menu-toggle {
  background-image: url("/images/icons/arrow-down-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: @collaborator-list-control-editable-icon-size;
  height: 2.5rem;
  width: 1.25rem;
  margin-right: 0.25rem;
}

.collaborator-remove {
  margin-right: 1rem;
  margin-left: 1rem;
}

.collaborator-remove--disabled {
  visibility: hidden;
}

.collaborator-remove__link {
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.5rem;
  color: @color-gray-light;

  &:hover {
    color: @color-gray-dark;
  }
}

/*
Collaborator List - Editor

Markup:
<div class="collaborator-list collaborator-list--editor">
  <div class="collaborator-list__title">
    Collaborators (<span class="collaborator-list__count">4</span>)
  </div>
  <div class="collaborator-list__items">
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-ernie.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Ernie</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-bert.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Bert - selecting role</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">editor</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--collaborator">
      <img src="/images/avatar-ernie.jpg" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">Ernie</span>
        <span class="collaborator-list__additional-actions"></span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">moderator</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
        <div class="menu menu--within-manage-collaborators menu--down">
          <div class="menu__content">
            <ul class="menu__items"><li class="menu__item menu__item--active">moderator</li></ul>
          </div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--invitee">
      <img src="/images/icons/png/envelope.png" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">ernie@sesamestreet.com</span>
        <span class="collaborator-list__additional-actions">Resend invite</span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">editor</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
    <div class="collaborator-list__item collaborator-list__item--invitee">
      <img src="/images/icons/png/envelope.png" class="collaborator-list__icon">
      <div class="collaborator-list__main">
        <span class="collaborator-list__name">ernie@sesamestreet.com - confirmation</span>
        <span class="collaborator-list__additional-actions collaborator-list__additional-actions--confirm">Sent!</span>
      </div>
      <div class="collaborator-role-selector">
        <div class="collaborator-role-selector__current-role">
          <div class="collaborator-role-selector__current-role-name">editor</div>
          <div class="collaborator-role-selector__menu-toggle"></div>
        </div>
      </div>
      <div class="collaborator-remove">
        <a class="collaborator-remove__link">×</a>
      </div>
    </div>
  </div>
</div>

Styleguide: CollaboratorList.editor
*/

.collaborator-list--editor {
  .collaborator-list__item {
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
  .collaborator-role-selector__current-role {
    color: @color-gray-light;
    cursor: default;
    &:hover {
      border: none;
    }
  }
  .collaborator-role-selector__menu-toggle {
    visibility: hidden;
  }
}
