/*
Flash

Markup:
<div class="flash">Flash is transparent by default</div>
<div class="flash flash--error">This is an error message</div>
<div class="flash flash--success">This is a success message</div>

Styleguide: Flash
*/

@keyframes flash {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.flash {
  height: 1.5em;
  opacity: 0;
  animation-duration: 4s;
  animation-timing-function: ease;
}

.flash--error {
  animation-name: flash;
  color: @color-primary;
}

.flash--success {
  animation-name: flash;
  color: @color-secondary;
}
