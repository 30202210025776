.legal-layout {
  .legal-menu {
    border-bottom: 1px solid @color-white-dark;
    text-align: center;

    &__item {
      display: inline-block;
      text-decoration: none;
      margin-bottom: -1px;
      padding: 0 1em 1em;
      color: @color-gray;

      &--active {
        border-bottom: 1px solid @color-black;
        color: @color-black;
      }
    }
  }

  .legal-content {
    &:extend(.container);
  }

  ul {
    font-weight: 200;
    margin-left: 2em;
  }
}
