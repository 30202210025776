/*
Dashboard Cell

Represents an individual board for display on a grid layout.

Markup:
<div class="board-cell">
  <div class="board-cell__thumbnail" style="background-image: url(/images/board-placeholder.png)"></div>
  <div class="board-cell__panel">
    <div class="board-cell__title">
      Guardant Health - Retro
    </div>
    <div class="board-cell__timestamps">
      <div class="board-cell__timestamp">updated: 01/27/15</div>
      <div class="board-cell__timestamp">created: 01/01/15</div>
    </div>
    <div class="board-cell__extended-attributes">
      <ul class="board-cell__avatar-list">
        <li class="board-cell__avatar"><i class="moderator"></i><img src="/images/avatar-bert.jpg" /></li>
        <li class="board-cell__avatar"><img src="/images/avatar-ernie.jpg" /></li>
        <li class="board-cell__avatar"><img src="/images/avatar-bert.jpg" /></li>
      </ul>
      <div class="board-cell__stat board-cell__member-count">
        <a href="#" title="Manage Collaborators" tabindex="-1">
          <i class="icon-group"></i> 4
        </a>
        <span>4</span>
      </div>
      <div class="board-cell__stat board-cell__sheet-count">
        <i class="icon-stack"></i> 4
      </div>
    </div>
  </div>
  <div class="board-cell__lightbox">
    <div class="board-cell__actions">
      <div class="board-cell__action board-cell__action--delete">
        <a href="#"><i class="icon-trash"></i></a>
      </div>
      <div class="board-cell__action board-cell__action--archive">
        <a href="#"><i class="icon-folder"></i></a>
      </div>
      <div class="board-cell__action board-cell__action--restore">
        <a href="#"><i class="icon-restore"></i></a>
      </div>
    </div>
    <button class="board-cell__view-button button">View Board</button>
  </div>
</div>

Styleguide: Dashboard.Cell
*/

@board-cell-background: @color-white;
@board-cell-panel-background: @color-white;
@board-cell-shadow-color: @color-gray-light;
@board-cell-height: 12rem;
@board-cell-width: 17rem;
@board-cell-thumbnail-height: 10rem;
@board-cell-avatar-size: 24px;

.board-cell {
  background-color: @board-cell-background;
  border: 1px solid @board-cell-shadow-color;
  height: @board-cell-height;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 2px @board-cell-shadow-color;
  margin: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: @board-cell-width;
  align-self: flex-end;

  // Reveal hidden panels on hover or keyboard select.
  &:hover,
  &:focus,
  &:active {
    .board-cell__extended-attributes {
      display: flex;
      flex: 0 0 100%;
    }

    .board-cell__lightbox {
      display: flex;
    }
  }
}

.board-cell__thumbnail {
  top: 5%;
  left: 5%;
  position: absolute;
  width: 90%;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: @board-cell-background;
  min-height: @board-cell-thumbnail-height;
}

.board-cell__panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  padding-bottom: 0.4rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: @board-cell-panel-background;
  border-top: 1px solid @board-cell-shadow-color;
  z-index: 2;
}

.board-cell__title {
  margin-top: 0.8rem;
  text-align: center;
  flex: 0 0 100%;
}

.board-cell__timestamps {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
}

.board-cell__timestamp {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  font-size: @font-size-xxs;
  font-style: italic;
  color: @color-gray-light;
  text-align: center;
  flex: 1;

  &:last-child {
    margin-left: 2px;
    border-left: 1px solid @color-gray-light;
  }
}

.board-cell__timestamp--unseen {
  color: @color-red;
}

.board-cell__title ~ * {
  margin-top: 0.25rem;
}

// Hidden by default until hover.
.board-cell__extended-attributes {
  display: none;
  .clearfix();

  padding-right: 0.5rem;
  padding-left: 1rem;
}

.board-cell__avatar-list {
  margin: 0;
  list-style: none;
  flex: 1;
}

.board-cell__avatar {
  position: relative;
  margin-right: @board-cell-avatar-size / 8;
  display: inline-block;
  img {
    margin-top: 0;
    height: @board-cell-avatar-size;
  }
  i {
    color: @color-red;
    position: absolute;
    bottom: @board-cell-avatar-size / 8;
    left: -@board-cell-avatar-size / 8;
    font-size: @board-cell-avatar-size * 0.6;
    &.moderator {
      background-image: url(/images/icons/badges/moderator.svg);
      width: 0.5rem;
      height: 0.5rem;
      background-size: 100%;
    }
  }
}

.board-cell__stat {
  margin-top: 0;
  margin-right: 7px;
  margin-left: 5px;
  font-size: @font-size-s;
  color: @color-gray;
  align-self: flex-end;

  span {
    font-size: @font-size-xxs;
    margin-left: 5px;
  }
}

.board-cell__member-count {
  a {
    text-decoration: none;
  }
  .icon-group:hover {
    color: @color-turquoise-dark;
  }
}

.board-cell__lightbox {
  display: none;
  height: 100%;
  width: 100%;
  margin-top: 0;
  position: relative;
  z-index: 1;
  flex: 0 0 100%;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
}

.board-cell__actions {
  justify-content: flex-end;
  align-self: flex-end;
}

.board-cell__action {
  float: right;
  font-size: @font-size-s;
  margin: 10px 10px 0 0;
  color: @color-gray-light;
  a {
    color: @color-gray-light;
    text-decoration: none;

    &:hover {
      color: @color-gray-dark;
    }
  }
}

.board-cell__view-button {
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  color: @color-white;
  background-color: fadeout(@color-gray-light, 50%);
  border: 1px solid @color-white;
  text-align: center;
  text-decoration: none;
  font-size: @font-size-s;
  max-width: 55%;
  margin-top: -3%;

  &:hover {
    color: @color-white;
  }
}

.board-cell__access-icon--public,
.board-cell__access-icon--private {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: -3px;
}

.board-cell__access-icon--public {
  background-image: url("/images/icons/unlocked_grey.svg");
}

.board-cell__access-icon--private {
  background-image: url("/images/icons/locked_grey.svg");
}
