/*
Payment layout (EXPERIMENTAL)

Styles used on experimental payment flows.
*/

@color-startup-plan: #2abbe7;
@color-business-plan: #b8d231;
@color-growth-plan: #e64845;

.plans-footer {
  padding-top: 3rem;
  text-align: center;

  a,
  a:hover {
    text-decoration: underline;
  }

  a:hover {
    color: @color-white-dark;
  }
}

.plans-list {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 3rem;
}

.plan-info {
  flex: 1 0;
  text-align: center;

  padding: 2rem 0;

  &.popular {
    border: 2px solid @color-startup-plan;
    border-radius: 5px;

    box-shadow: 0 2px 0 0 fade(@color-black, 20%);
  }

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    padding: 0.75em;

    &.bold {
      font-weight: bold;
    }
  }

  i {
    height: 2rem;
    width: 2rem;
  }

  .button {
    border: 2px solid white;
    color: white;
  }

  .button:hover {
    border: 2px solid;
    transition: none;
  }

  .amount {
    font-size: @font-size-xl;
    display: inline;
  }

  &.plan-business {
    .amount {
      color: @color-business-plan;
    }
    .button {
      background-color: @color-business-plan;
    }
    .button:hover {
      background: white;
      border-color: @color-business-plan;
      color: @color-business-plan;
    }
  }

  &.plan-growth {
    .amount {
      color: @color-growth-plan;
    }
    .button {
      background-color: @color-growth-plan;
    }
    .button:hover {
      background: white;
      border-color: @color-growth-plan;
      color: @color-growth-plan;
    }
  }

  &.plan-startup {
    .amount {
      color: @color-startup-plan;
    }
    .button {
      background-color: @color-startup-plan;
    }
    .button:hover {
      background: white;
      border-color: @color-startup-plan;
      color: @color-startup-plan;
    }
  }
}

.payment-layout {
  .page-content {
    margin: 0 auto 2.5rem;
    max-width: 32rem;
  }
  .page-content--centered {
    text-align: center;
  }
}

.payment__form {
  text-align: center;
  margin: 0 auto;
  padding-top: 1.5rem;
  border-top: 1px solid @color-white-dark;

  h3,
  h4 {
    font-weight: normal;
    line-height: 1.4em;
  }

  form {
    text-align: left;

    label {
      display: inline-block;
      text-align: left;
      font-weight: bold;
      padding-bottom: 1rem;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      text-indent: 0.5rem;

      &.half-width {
        width: 50%;
      }

      input {
        font-weight: normal;
        box-sizing: border-box;
        padding: 0.5rem;
        margin-top: 0.5rem;
        display: block;
        width: 100%;
        border: 1px solid @color-white-dark;
        border-radius: 3px;
        height: initial;
        background-color: white;

        &.invalid {
          border-color: @color-growth-plan;
        }
      }
    }

    .form-actions {
      padding: 0;
      margin: 1rem 0;
      background: none;
      border: none;
      text-align: center;
    }
  }
}

#modal #modal-box.payment-experiment {
  max-width: 33em;
}
