// Typography
//
// Base typography styles.
//
// Style guide: Typography

html {
  font-family: @font-family-base;
  font-size: @font-size-base;
  color: @color-text;
  line-height: @line-height-base;

  @media @screen-xl {
    font-size: @font-size-base * 1.1;
  }
}

a {
  color: @color-link;
  text-decoration: underline;

  &:hover {
    color: @color-link-hover;
    text-decoration: none;
  }
}

/*
Headers

Markup:
<h1>H1</h1>
<h2>H2</h2>
<h3>H3</h3>

Weight: 1

Style guide: Typography.Headers
*/

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
  line-height: @line-height-base;
  font-family: @font-family-base;
  font-weight: bold;
  color: @color-text;
  text-rendering: optimizelegibility;
}

h1 {
  font-size: @font-size-xxl;
}
h2 {
  font-size: @font-size-xl;
}
h3 {
  font-size: @font-size-l;
}
h4 {
  font-size: @font-size-m;
}

/*
Content

Standard content formatting

Markup:
<p>This is what body text looks like. It's rather small and kind of weak
looking compared to all these giant headers</p>
<a href="#">This is what a link looks like.</a>

Styleguide: Typography.Content
*/

p {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 1.25rem;
  margin-left: 0;
  font-size: 1rem;
  font-weight: 200;
}

p + h1,
p + h2,
p + h3,
p + h4 {
  margin-top: 2rem;
}

small {
  font-size: @font-size-base;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

cite {
  font-style: normal;
}

/*
Lists

Basic list styling.

Markup:
<div class="text-center">Centered text</div>
<ul>
  <li>Item One</li>
  <li>Item Two</li>
</ul>
<ol>
  <li>Item One</li>
  <li>Item Two</li>
</ol>

Style guide: Typography.Lists
*/

ul,
ol {
  padding: 0;
  margin: 0 0 (@line-height-base / 2) @line-height-base;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

li {
  line-height: @line-height-base;
}

/*
Alignment

Markup:
<div class="text--center">Centered Text</div>

Styleguide: Typography.Alignment
*/
.text--center {
  text-align: center;
}

/*
Hard Rules

Markup:
<hr />

Styleguide: Typography.HardRule
*/

hr {
  margin: @line-height-base 0;
  border: 0;
  border-top: 1px solid @color-hr;
  border-bottom: 1px solid @color-white;
}
