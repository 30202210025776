/*
Modal (new)

Markup:
<div class="modal--updated">
  <div class="modal__overlay"></div>
  <div class="modal__container">
    <div class="modal__box">
      <div class="modal__header">Join for free.</div>
      <div class="modal__subheader">(No credit card required.)</div>
      <div class="modal__body">Better collaboration is two clicks away:</div>
      <div class="signup__providers"><a id="btn-google" href="oauth/google" class="btn btn-provider"><i class="icon-google"></i><span>Sign up with Google</span></a><a id="btn-twitter" href="oauth/twitter" class="btn btn-provider"><i class="icon-twitter"></i><span>Sign up with Twitter</span></a></div>
      <div class="signup__opt-out"><a href="sign-up/email" class="a_signup-email-preferred">Prefer to sign up with email</a></div>
    </div>
  </div>
</div>

Styleguide: Modal
*/

.modal__overlay,
.modal__container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.modal__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__overlay {
  background: rgba(61, 31, 11, 0.7);
}

.modal__box {
  position: relative;
  border-radius: 0.15rem;
  background: @color-white;
  padding: 4rem 5rem 3rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.modal__header-icon {
  font-size: @font-size-xl;
  text-align: center;
  margin: -1rem 0 1rem;
}

.modal__header,
.modal__subheader {
  line-height: 1.4em;
  text-align: center;
}

.modal__header {
  font-size: @font-size-xl;
  padding-bottom: 0.5rem;
}

.modal__subheader {
  font-size: @font-size-m;
}

.modal__body {
  padding-top: 3rem;
  padding-bottom: 1rem;
}

.modal__body--no-padding {
  padding-top: 1rem;
}

.modal__content {
}

.modal__text--left {
  text-align: left;
}

.modal__buttons {
  padding-top: 1rem;
}

.modal__button,
input.modal__button {
  .button;
  .button--primary;
  display: block;
  margin: 1rem auto 0;
  width: 12rem;
  text-decoration: none;
}

.modal__form {
}

input.modal__input {
  display: block;
  margin: 2rem 0 0;
  background: none;
  border: none;
  border-bottom: 0.15rem solid @color-gray;
  border-radius: 0;
  padding: 0.5em;
  width: 18rem;
}
