.portal-login__or-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.portal-login__or-line {
  width: 100%;
}

.portal-login__or-text {
  margin: 0 14px;
  margin-bottom: 6px;
}
