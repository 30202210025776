.footer__padding {
  font-size: @font-size-xs;
  padding-bottom: 5em;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 0;
  background: @color-white-off;
  color: @color-gray;
  font-size: @font-size-xs;
  font-weight: 300;
  z-index: 2;

  &__container {
    &:extend(.container);

    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    color: @color-gray;

    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
