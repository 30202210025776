/*
Forms

Base form and control styling.

Markup:
<form class="form">
  <div class="form__group">
    <label class="form__label">Basic Input</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <div class="form__group form__group--half">
    <label class="form__label">First Half</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <div class="form__group form__group--half">
    <label class="form__label">Second Half</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <div class="form__group form__group--third">
    <label class="form__label">First Third</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <div class="form__group form__group--third">
    <label class="form__label">Second Third</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <div class="form__group form__group--third">
    <label class="form__label">Third Third</label>
    <input class="form__control" placeholder="Placeholder" />
  </div>
  <hr />
  <div class="form__group">
    <label class="form__label">Password</label>
    <input class="form__control" type="password" value="password" />
  </div>
  <div class="form__group">
    <label class="form__label">Email</label>
    <input class="form__control" type="email" placeholder="user@example.com" required />
  </div>
  <hr />
  <div class="form__group">
    <label class="form__label">Buttons</label>
    <input class="button button--primary form__button form__button--submit" type="submit" value="Submit" />
    <input class="button form__button form__button--cancel" type="submit" value="Cancel" />
    <input class="button form__button" disabled="disabled" type="submit" value="Disabled" />
  </div>
</form>

Styleguide forms
*/

@input-padding: @vertical-rhythm / 2;
@label-padding: 0.1rem;

.form {
}

.form--inline {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
}

.form__header {
}

.form__group,
.form__label,
.form__control {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.form__group {
  display: inline-block;
  width: 100%;
  padding-bottom: @vertical-rhythm;
  padding-right: @vertical-rhythm;
}

.form__group--half {
  width: 50%;
  margin-right: -5px;
}

.form__group--third {
  width: 33%;
  margin-right: -1px;
}

.form__group--flush {
  padding-right: 0;
}

.form__label {
  font-weight: bold;
  font-size: 0.875rem;
  padding-bottom: @label-padding;
}

.form__label--medium {
  font-weight: 500;
}

.form__label--sub {
  font-weight: 500;
  opacity: 0.37;
}

.form__label--invalid {
  color: @color-accent;
}

.form__label--sub.form__label--invalid {
  opacity: 1;
}

.form__control {
  font-size: 0.875rem;
  border-radius: @input-radius-border;
  border: 1px solid @input-color-border;
  padding: @input-padding;
}

.form__control--invalid {
  border-color: @color-accent;
}

.form__control--no-border {
  border: none;
}

.form__button {
  &:extend(.form__control);
  &:extend(.button);

  width: auto;
  min-width: 10em;
  text-align: center;
  margin-right: @line-height-half;

  &:disabled {
    background-color: @color-white-dark;
    color: @color-gray-light;
    border-color: @color-gray-light;
  }
}

.form__button--submit {
}

.form__button--cancel {
}
