/*
Text Hero

A subtle hero banner meant for simple text headings

Markup:
<div class="text-hero">
  <div class="text-hero__heading">Let's get started!</div>
</div>

Styleguide: TextHero
*/

@text-hero-offset: @header-height;
.text-hero {
  font-size: @font-size-xl;
  text-align: center;
  padding: 5rem;
  margin: @text-hero-offset auto @vertical-rhythm;
  background-color: @color-white-off;
}

.text-hero__heading {
  font-weight: normal;
}
