// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

.outlined-input {
  &:focus {
    border-width: 1px;
    border-radius: @border-radius-small;
    border-color: darken(@color-gray, 10%);
    box-shadow: 0 0 6px lighten(@color-gray, 20%);
    outline: 0;

    &:required:invalid {
      color: #b94a48;
      border-color: darken(#ee5f5b, 10%);

      &:focus {
        border-color: darken(#ee5f5b, 10%);
        box-shadow: 0 0 6px lighten(#ee5f5b, 20%);
      }
    }
  }
}
