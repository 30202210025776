.home {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.home__heading {
  position: relative;
  flex: 1 0 auto;
  margin-bottom: 3rem;

  @media @screen-xxs {
    margin-bottom: 8rem;
  }

  @media @screen-s {
    display: flex;
    justify-content: center;
  }
}

.home__heading-main {
  margin: auto;
  padding: 0 0 3rem 0;
  width: 20rem;
  font-family: futura-pt, sans-serif;
  font-size: 1.2rem;
  color: @color-deep;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0;

  @media @screen-s {
    margin-left: 1rem;
    margin-right: 1rem;
    order: 2;
  }
}

.home__heading-character {
  height: 16rem;
  width: auto;
  position: absolute;
  top: 29rem;

  &:first-of-type {
    left: -2%;
  }

  &:last-of-type {
    right: -8%;
  }

  @media @screen-xs {
    height: 18rem;

    &:last-of-type {
      right: -6%;
    }
  }

  @media @screen-s {
    height: 22.5rem;
    position: static;
    align-self: flex-end;

    &:first-of-type {
      order: 1;
    }

    &:last-of-type {
      order: 2;
    }
  }

  @media @screen-m {
    height: 35rem;
  }
}

.home__header {
  margin-top: 0;
  font-family: futura-pt-bold, sans-serif;
  color: @color-deep;
}

.home__subheader {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 4rem;
  font-family: futura-pt, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: @color-deep;
}

.home__cta {
  margin-bottom: 10rem;

  @media @screen-xxs {
    margin-bottom: 7rem;
  }

  @media @screen-s {
    margin-bottom: 4rem;
  }
}

.home__credit {
  font-size: 0.8rem;
  display: flex;
  height: 2.2rem;
  margin-bottom: 3rem;
}

.home__credit-line {
  max-width: 75%;
  margin: auto;
  margin-bottom: 0;
  align-self: flex-end;

  @media @screen-xs {
    max-width: 100%;
  }
}

.home__heading-constellation {
  position: absolute;
  top: 0;
  background-image: url("/images/backgrounds/home-constellation-mobile-1.svg");
  background-repeat: no-repeat;
  background-size: 105% auto;
  width: 100%;
  height: 115%;
  content: " ";
  z-index: -1;

  @media @screen-xxs {
    background-size: 100% auto;
  }

  @media @screen-m {
    width: 100%;
    height: 100%;
    background-image: url("/images/backgrounds/home-constellation-desktop-1.svg");
  }

  @media @screen-l {
    background-size: 70rem auto;
    background-position: center;
  }
}

.home__features {
  position: relative;
  align-self: stretch;
  flex: 1 0 auto;
  margin-bottom: 15rem;

  @media @screen-xxs {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
  }

  @media @screen-s {
    flex-direction: row;
    justify-content: center;
  }
}

.home__section-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  @media @screen-s {
    flex-direction: row;
    justify-content: center;
    max-width: 50rem;
    margin: auto;
    margin-bottom: 0;
  }

  @media @screen-m {
    max-width: 60rem;
  }
}

.home__features-main {
  @media @screen-s {
    flex: 0 1 23rem;
    margin-bottom: 6rem;
  }
}

.home__features-illustration {
  margin-right: 1rem;

  @media @screen-xxs {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  @media @screen-s {
    margin-right: 0;
    flex: 0 1 23rem;
    align-self: flex-end;
  }
}

.home__features-bulb {
  height: 7rem;
  width: auto;
  position: absolute;
  right: 4%;
  top: 27rem;

  @media @screen-xxs {
    position: static;
  }

  @media @screen-xs {
    height: 9rem;
  }

  @media @screen-s {
    height: 13rem;
  }

  @media @screen-l {
    height: 20rem;
  }
}

.home__features-character {
  height: 6rem;
  width: auto;
  position: absolute;
  top: 33rem;
  right: -2%;

  @media @screen-xxs {
    position: static;
  }

  @media @screen-xs {
    height: 8rem;
  }

  @media @screen-s {
    height: 12rem;
  }

  @media @screen-l {
    height: 18rem;
  }
}

.home__features-backing {
  position: absolute;
  width: 100%;
  height: 170%;
  top: -7rem;
  z-index: -1;

  @media @screen-xxs {
    height: 135%;
    top: -10rem;
  }

  @media @screen-s {
    height: 145%;
    top: -12rem;
  }
}

.home__feature-list {
  list-style-type: none;
}

.home__feature-list-item {
  font-family: futura-pt, sans-serif;
  color: @color-deep;
  list-style-type: none;
}

.home__feature-list-item-header {
  margin-bottom: 0;
  font-family: futura-pt-bold, sans-serif;
  color: @color-deep;
  list-style-type: none;
}

.home__retro {
  position: relative;
  flex: 1 0 auto;
  align-self: stretch;
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media @screen-s {
    flex-direction: row;
    display: flex;
    margin-bottom: 10rem;
  }
}

.home__retro-illustration {
  width: 100%;
  max-width: 19rem;
  align-self: center;

  @media @screen-s {
    max-width: none;
    width: auto;
    align-self: flex-start;
    height: 45rem;
    flex: 0 1 30rem;
    margin-right: 1.5rem;
    order: 1;
  }

  @media @screen-m {
    height: 50rem;
  }

  @media @screen-l {
    height: 60rem;
  }
}

.home__retro-main {
  @media @screen-s {
    flex: 0 1 20rem;
    align-self: flex-end;
    order: 2;
  }
}

.home__retro-constellation {
  position: absolute;
  top: 14rem;
  background-image: url("/images/backgrounds/home-constellation-mobile-2.svg");
  background-repeat: no-repeat;
  background-size: 100% 64%;
  width: 100%;
  height: 100vh;
  content: " ";
  z-index: -1;

  @media @screen-s {
    top: -8rem;
    background-image: url("/images/backgrounds/home-constellation-desktop-2.svg");
  }

  @media @screen-l {
    top: -31rem;
    background-size: 70rem auto;
    background-position: center;
  }
}

.home__story {
  position: relative;
  flex: 1 0 auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media @screen-s {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.home__story-main {
  @media @screen-s {
    flex: 0 1 23rem;
  }
}

.home__story-graph {
  font-family: futura-pt, sans-serif;
  color: @color-deep;
}

.home__story-illustration {
  margin-right: 1rem;
  height: 15rem;
  width: auto;

  @media @screen-xxs {
    align-self: center;
  }

  @media @screen-xs {
    height: 20rem;
  }

  @media @screen-s {
    margin-right: 0;
    align-self: flex-end;
    height: 25rem;
    flex: 0 1 23rem;
  }

  @media @screen-l {
    height: 38rem;
  }
}

.home__story-backing {
  position: absolute;
  width: 100%;
  height: 120%;
  top: -5rem;
  z-index: -1;
}

.home__story-constellation {
  display: none;

  @media @screen-s {
    display: inline;
    position: absolute;
    top: -8rem;
    background-image: url("/images/backgrounds/home-constellation-mobile-2.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 150%;
    content: " ";
    z-index: -1;
    background-image: url("/images/backgrounds/home-constellation-desktop-3.svg");
  }

  @media @screen-l {
    top: -19rem;
    background-size: 70rem auto;
    background-position: center;
  }
}
