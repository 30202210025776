.features {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 10% 26% 12%;
  background-image: url("/images/sticky-column.svg");
  background-repeat: space no-repeat;
  background-position: bottom;
  background-size: 6.25%;

  @media @screen-s {
    flex-wrap: wrap;
    flex-direction: row;
    background-size: auto;
    padding-bottom: 15rem;
  }
}

.features__section-main {
  display: flex;
  flex-direction: column;

  @media @screen-s {
    flex: 0 1 45%;
  }
}

.features__header {
  font-family: futura-pt-bold, sans-serif;
  margin-top: 0;
  font-size: 2rem;
  line-height: 1.2;

  @media @screen-s {
    font-size: 3.125rem;
  }
}

.features__subheader {
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.375rem;

  @media @screen-s {
    font-size: 2rem;
  }
}

.features__body {
  font-family: futura-pt, sans-serif;
  font-size: 1.125rem;
  color: @color-deep;

  @media @screen-s {
    font-size: 1.5rem;
    margin: 0;
  }
}

.features__section-board {
  max-width: 35.5rem;
  margin-bottom: 3.375rem;
  position: relative;

  @media @screen-s {
    margin-bottom: 0;
    margin-left: auto;
    flex: 0 1 45%;
    align-self: flex-end;
  }
}

.sticky-tip {
  position: absolute;

  .tip-dot {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    line-height: 1.6rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: @color-black;
    font-size: 0.9rem;
    color: @color-white;
    text-align: center;
    z-index: 1;
  }

  .tip-dot:hover {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    background-color: @color-red;
    cursor: default;
  }

  .tip-note {
    position: relative;
    display: none;
    width: 10rem;
    height: 10rem;
    transform: translate(-1.5rem, -1rem);
    margin-top: 0.5rem;
    padding: 0.3rem;
    color: @color-black;
    font-size: 0.7rem;
    background-color: @color-yellow;
    box-shadow: 2px 2px 8px @color-gray;
    z-index: 2;
  }

  .tip-dot:hover + .tip-note {
    display: block;
  }
}

.tip-1 {
  top: 4%;
  left: 43%;
}

.tip-2 {
  top: 11%;
  left: 3%;
}

.tip-3 {
  top: 18%;
  left: 80%;
}

.tip-4 {
  top: 39%;
  left: 15%;
}

.tip-5 {
  top: 69%;
  left: 50%;
}

.features__board {
  width: 100%;
  height: auto;
}

.features__section-button {
  margin-bottom: 1.25rem;

  @media @screen-s {
    width: 50%;
  }
}

.features__section-characters {
  display: flex;
  justify-content: flex-end;

  @media @screen-s {
    flex: 0 0 50%;
    padding-top: 5.12rem;
  }
}

.features__character-larry {
  height: auto;
  flex-basis: 25%;

  @media @screen-s {
    flex: 0 1 13.81rem;
  }
}

.character__larry {
  width: 100%;
  height: auto;
}

.features__character-megan {
  height: auto;
  margin-right: 3.75rem;
  flex-basis: 21%;

  @media @screen-s {
    flex: 0 1 12.18rem;
  }
}

.character__megan {
  width: 100%;
  height: auto;
}

#feature-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 630px;
  margin: 50px 0;
}
