/*
<div id="sheet-list" class=""><div class="sheet-toolbar">
  <div class="header-control">
    <input type="text" class="sheet-name editable" autocomplete="off">
    <div class="toggle-sheet-choices"></div>
  </div>
</div>
*/

#sheet-list {
  .menu__items {
    max-height: calc(~"100vh - 80px");
    overflow-y: auto;
  }

  &.sheet-list--role-moderator .menu__items {
    max-height: calc(~"100vh - 200px");
  }

  &.sheet-list--role-editor .new-sheet {
    display: none;
  }
}
