.header-logo__container {
  padding: 2rem 12%;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-logo__nav-container {
  width: 25rem;
  display: none;
  flex-direction: row;
  justify-content: space-between;

  @media @screen-s {
    display: flex;
  }
}
