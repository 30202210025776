/*
Lead copy

Markup:
<div class="lead">
  <div class="lead__header">Real-time sticky notes for teams</div>
  <div class="lead__text">
    Create, move, cluster and upvote stickies in real-time. Perfect for affinity maps, retrospectives and brainstorming sessions.
  </div>
</div>

Styleguide: Lead
*/

.lead {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.lead__header {
  color: #f75e50;
  font-size: @font-size-xl;
  font-weight: bold;
}

.lead__text {
  font-size: @font-size-l;
  font-weight: 200;
}
