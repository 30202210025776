/*
Menu

Contextual menus for various stickies action (see: presence bar, header).

`menu--borderless`: removes borders around the menu & within the menu.

`menu--fullsize`: Opens the menu to 100% of the container.

`menu__item--disabled`: disables cursor inputs, applies greyed-out styles.

`menu__item--active`: Highlights currently active / selected menu item.

`menu__item--control`: style the menu item to hold form controls.

`menu__item--indented`: add additional text indentation on the menu

`menu__item--button`: special button-style menu item that contains a `menu__button`

Markup:
<div class="menu">
  <div class="menu__content">
    <ul class="menu__items">
      <li class="menu__item">Menu Item 1</li>
      <li class="menu__item menu__item--active">Menu Item 2 (Active)</li>
      <li class="menu__item menu__item--indented">Menu Item 3 (Indented)</li>
      <li class="menu__item menu__item--disabled">Menu Item Disabled</li>
      <li class="menu__item">
        <div class="menu__header">Menu Item 4</div>
        <div class="menu__copy">With extra content in it to make for a rather large menu item.</div>
      </li>
      <li class="menu__item menu__item--button">
        <div class="button menu__button menu__button--primary">
          <i class="png-icon-moderator png-icon-moderator--white"></i>
          Make Moderator
        </div>
      </li>
      <li class="menu__item menu__item--button">
        <div class="button menu__button">Sign out of Stickies</div>
      </li>
    </ul>
    <div class="menu__item menu__item--control">
      <input class="menu__input" placeholder="Menu Input" />
      <button class="button menu__button menu__button--primary">Menu Button</button>
    </div>
  </div>
</div>
<hr />
<div class="menu menu--borderless">
  <div class="menu__content">
    <ul class="menu__items">
      <li class="menu__item">Menu Item 1</li>
      <li class="menu__item">Menu Item 2</li>
      <li class="menu__item">
        <div class="menu__header">Menu Item 3</div>
        With extra content in it to make for a rather large menu item.
      </li>
    </ul>
  </div>
</div>

Styleguide: Menu
*/

/*
Right Flyout Menu

Opens out from the right side. Must be contained in a relatively-positioned container.

Markup:
<div style="background:black; width: 300px; height: 300px; position: relative;">
<div class="menu menu--right menu--open">
  <div class="menu__content" style="width: 200px">
    <ul class="menu__items">
      <li class="menu__item">Right Menu Item 1</li>
      <li class="menu__item">Menu Item 2</li>
    </ul>
  </div>
</div>
</div>

Styleguide: Menu.Right
*/

@menu-background-color: @color-white;
@menu-hover-text-color: @color-black;
@menu-hover-background-color: @color-white-dark;
@menu-border-color: @color-white-dark;
@menu-inactive-background-color: @color-white-dark;
@menu-active-background-color: @color-white;
@menu-active-text-color: @color-black;
@menu-font-size: @font-size-s;
#kssref-menu {
  .menu {
    position: relative;
    max-width: 400px;
  }
}

.menu {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  font-size: @menu-font-size;
}

.menu--full-size {
  width: 100%;
}

.menu--borderless {
  .menu__item {
    border-color: transparent;
  }
  .menu__items {
    border-color: transparent;
  }
}

.menu--down {
  top: 100%;
  left: 0;

  .menu__content {
    transform: translateY(-100%);
  }
}

.menu--right {
  top: 0;
  left: 100%;

  .menu__content {
    transform: translateX(-100%);
  }
}

.menu--open {
  .menu__content {
    transform: translateX(0%) translateY(0%);
  }
}

.menu__content {
  transition: transform @transition-duration;
  pointer-events: all;
}

.menu__items {
  list-style: none;
  border: 1px solid @menu-border-color;
  margin: 0;
  padding: 0;
}

.menu__item {
  padding: 0.9rem 0.5rem;
  background-color: @menu-background-color;
  border-bottom: 1px solid @menu-border-color;
  cursor: pointer;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: @menu-hover-text-color;
    background-color: @menu-hover-background-color;
  }

  &:first-letter {
    // text-transform: uppercase;
  }
}

.menu__item--disabled {
  color: @color-gray;
  background-color: @menu-inactive-background-color;
  cursor: default;
  pointer-events: none;

  &:hover {
    color: @color-gray;
    background-color: @menu-inactive-background-color;
  }
}

.menu__item--active {
  font-weight: bold;
  background-color: @menu-active-background-color;
  color: @menu-active-text-color;
}

.menu__item--control {
  cursor: default;
  background-color: @color-white-dark;
  &:hover {
    color: inherit;
    background-color: @color-white-dark;
  }
}

// This needs higher specificity to override .menu--borderless
.menu {
  .menu__item--border-top {
    border-top: 1px solid @menu-border-color;
  }
}

/*
Menu - Fancy item

Markup:
<div class="menu">
  <div class="menu__content">
    <ul class="menu__items">
      <li class="menu__item menu__item--fancy">
        <div class="menu__header"><i class="png-icon-locked"></i> Menu Item 4</div>
        <div class="menu__copy">With extra content in it to make for a rather large menu item.</div>
      </li>
      <li class="menu__item menu__item--fancy">
        <div class="menu__header"><i class="png-icon-locked"></i> Menu Item 4</div>
        <div class="menu__copy">With extra content in it to make for a rather large menu item.</div>
      </li>
    </ul>
  </div>
</div>

Styleguide: Menu.FancyItem
*/
.menu__item--fancy {
  padding: 1rem;
  i {
    vertical-align: baseline;
  }
}

.menu__header {
}

.menu__copy {
  font-size: @font-size-xxs;
}

.menu__input {
  &:extend(.form__control);
  margin-bottom: 0.5em;
  padding: 0.5rem 0.5rem;
}

.menu__item--button {
  &:hover {
    background-color: @color-white;
  }
}

.menu__item--indented {
  text-indent: 2em;
}

/*
Menu Button

Markup:
<div class="button menu__button">Push me</div>
<div class="button menu__button menu__button--primary">Push me</div>

Styleguide: Menu.Button
*/

.menu__button {
  width: 100%;
  font-size: @menu-font-size;
}

.menu__button--primary {
  .button-template(@color-primary, @color-primary, @color-white);
}

/*
Header Control Menus

Menus that drop out of header controls

Markup:
<div class="header">
  <div class="header__control">
    <input type="text" class="sheet-name header__input header__input--editable header__editable-menu"
           autocomplete="off" placeholder="when menu open">
    <div class="menu menu--within-header-control menu--down menu--open">
      <div class="menu__content">
        <ul class="menu__items"><li data-id="564bbab8a3c818c1219d9a86" class="menu__item sheet-choice menu__item--active">My test sheet</li></ul>
        <div class="new-sheet menu__item menu__item--control">
          <input class="new-sheet-name menu__input" required="" type="text" placeholder="11/18/2015">
          <button class="button menu__button menu__button--primary">Add Sheet</button>
        </div>
      </div>
    </div>
  </div>
</div>

Styleguide: Menu.HeaderControl
*/
.menu--within-header-control {
  width: calc(~"100% - 0.5rem - 0.7rem");
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
