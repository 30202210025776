.informational {
  display: flex;
  margin-bottom: 2rem;
}

.informational__icon {
  width: 51px;
  height: 51px;
  background-color: @color-informational;
  background-image: url("/images/icons/light-bulb.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.informational__message {
  font-size: 14px;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
}

.form-avatar {
  display: flex;
}

.form-avatar__image-container {
  margin-top: auto;
  margin-bottom: auto;
}

.form-avatar__image {
  width: 48px;
  height: 48px;
  display: block;
}

.form-avatar__message {
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  font-size: 14px;
}

.profile-details-form {
  margin-bottom: 6rem;
}

.profile-password-form {
  margin-bottom: 20rem;
}
