/*
Guide Tooltip

A bold tooltip meant for onboarding tours.

Markup:
<div class="guide-tooltip">
  <h3>Make a menu choice</h3>
  <p>Have you considered trying the steak?</p>
  <a href="#" class="button button--primary">Tasty!</a>
</div>
<br />
<br />
<div class="guide-tooltip guide-tooltip--left">
  <h3>Choose a theme!</h3>
  <a href="#" class="button button--primary">Got it!</a>
</div>
<br />
<br />
<div class="guide-tooltip guide-tooltip--low-left">
  <h3>Invite collaborators here</h3>
  <a href="#" class="button button--primary">High five!</a>
</div>
<br />
<div class="guide-tooltip guide-tooltip--upper-left">
  <h3>Invite collaborators here</h3>
  <a href="#" class="button button--primary">Sure, man!</a>
</div>

Styleguide: GuideTooltip
*/

@guide-tooltip-base-color: @color-white;
@guide-tooltip-border-color: @color-red;
@guide-tooltip-pointer-width: 16px;
@guide-tooltip-border-width: 3px;
@guide-tooltip-outer-pointer-width: @guide-tooltip-pointer-width + @guide-tooltip-border-width;

.guide-tooltip {
  text-align: center;
  position: relative;
  background: @guide-tooltip-base-color;
  border: 2px solid @guide-tooltip-border-color;
  border-radius: 5px;
  padding: 20px;
  width: 400px;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: fade(@guide-tooltip-base-color, 0);
  }

  &:after {
    border-color: transparent transparent @guide-tooltip-base-color transparent;
    border-width: @guide-tooltip-pointer-width;
    margin: 0 0 0 -@guide-tooltip-pointer-width;
  }

  &:before {
    border-color: transparent transparent @guide-tooltip-border-color transparent;
    border-width: @guide-tooltip-outer-pointer-width;
    margin: 0 0 0 -@guide-tooltip-outer-pointer-width;
  }
}

.guide-tooltip--left {
  &:after,
  &:before {
    bottom: 50%;
    right: 100%;
    left: auto;
  }

  &:after {
    border-color: transparent @guide-tooltip-base-color transparent transparent;
    margin: 0 0 -13px 0;
  }

  &:before {
    border-color: transparent @guide-tooltip-border-color transparent transparent;
    margin: 0 0 -16px 0;
  }
}

.guide-tooltip--low-left {
  &:after,
  &:before {
    bottom: 0%;
    right: 100%;
    left: auto;
  }

  &:after {
    border-color: transparent @guide-tooltip-base-color transparent transparent;
    margin: 0 0 17px 0;
  }

  &:before {
    border-color: transparent @guide-tooltip-border-color transparent transparent;
    margin: 0 0 14px 0;
  }
}

.guide-tooltip--upper-left {
  &:after,
  &:before {
    top: 0%;
    right: 100%;
    left: auto;
  }

  &:after {
    border-color: transparent @guide-tooltip-base-color transparent transparent;
    margin: 17px 0 0 0;
  }

  &:before {
    border-color: transparent @guide-tooltip-border-color transparent transparent;
    margin: 14px 0 0 0;
  }
}
