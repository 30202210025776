/*
Presence bar

Notification/action section of the screen.

Markup:
<div class="presence-bar">
  <div class="presence-bar__section current">
    <div class="online">
      <div class="presence-bar__collaborator current-collaborator">
        <div class="presence-bar__avatar">
          <img src="/images/avatar-ernie.jpg">
          <div class="presence-bar__badges">
            <div class="presence-bar__badge presence-bar__badge--moderator presence-bar__badge--active"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="collaborator-bar">
    <div class="wrapper">
      <div class="presence-bar__section moderators">
        <div class="online">
          <div class="presence-bar__collaborator other-collaborator">
            <div class="presence-bar__avatar">
              <img src="/images/avatar-bert.jpg">
              <div class="presence-bar__badges">
                <div class="presence-bar__badge presence-bar__badge--moderator"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="offline"></div>
      </div>
      <div class="presence-bar__section collaborators">
        <div class="online"></div>
        <div class="offline">
          <div class="presence-bar__collaborator other-collaborator">
            <div class="presence-bar__avatar">
              <img src="/images/avatar-bert.jpg">
              <div class="presence-bar__badges">
                <div class="presence-bar__badge presence-bar__badge--moderator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="presence-bar__counter">
    <div class="online">
      <div class="icon"></div>
      <div class="counter">2</div>
    </div>
    <div class="offline">
      <div class="icon"></div>
      <div class="counter">1</div>
    </div>
  </div>
  <div class="presence-bar__manage-collaborators" style="display: block;"></div>
</div>

Styleguide: PresenceBar
*/

#kssref-presencebar,
#kssref-presencebar + section,
#kssref-presencebar + section + section,
#kssref-presencebar + section + section + section {
  .kss-modifier__example {
    height: 400px;
    position: relative;

    .presence-bar {
      top: 0;
      position: absolute;
    }
  }
}

@collaborator-hover-color: rgb(208, 212, 212);
@collaborator-highlight-color: @color-primary;

@header-height: 44px;
@row-width: 80px;
@presence-bar-avatar-width: 48px;
@presence-bar-menu-width: 16rem;
@presence-bar-width: 24px;
@presence-bar-padding: (@row-width - @presence-bar-avatar-width) / 2;

.presence-bar {
  position: fixed;
  top: @header-height;
  left: 0;
  bottom: 0;
  width: @row-width;
  display: flex;
  flex-direction: column;
  background: @color-white-dark;
}

.presence-bar__section {
  border-bottom: 2px solid @color-white;

  .online .last-activity label {
    display: none;
  }

  &.moderators {
    border-top: 2px solid @color-white;
  }
}

/**
 * The scrollable portion of the presence bar
 */

.collaborator-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */ /* Firefox broken without this, flyouts broken with this */
  height: 0px; // hack

  .wrapper {
    position: relative;
    flex-grow: 1;
  }
}

.collaborator-bar__shadow {
  content: "";
  width: 100%;
  height: 0px;
  position: absolute;
  left: 0px;
}

.collaborator-bar:before {
  .collaborator-bar__shadow;

  box-shadow: -2px 0px 8px 1px @color-gray;
  z-index: 1;
  top: 0px;
}

.collaborator-bar {
  .wrapper:after {
    .collaborator-bar__shadow;

    box-shadow: -2px 0px 30px 3px fade(@color-gray, 85%);
    z-index: 1;
    bottom: 0px;
  }
}

/**
 * Collaborator fly-out menus
 */
.presence-bar__collaborator {
  .menu {
    width: @presence-bar-menu-width + @presence-bar-width;
  }

  .menu__content {
    width: @presence-bar-menu-width;
    background: @menu-background-color;
    border: 1px solid @menu-border-color;
    border-left: none;
    box-sizing: border-box;
  }
}

.presence-bar__user-info {
  padding: 1.5em 0 0.5em 1.5em;

  .display-name {
    font-weight: bold;
    color: @color-gray;
  }

  .last-activity {
    font-style: italic;
    padding-top: 0.25em;
    color: @color-gray-light;

    label {
      display: inline;
      font-size: inherit;
    }
  }
}

.actions {
  .close-drawer--small {
    position: absolute;
    font-size: @font-size-xl;
    top: 0.25em;
    right: 0.5em;
    color: @color-gray-light;
    cursor: pointer;
  }

  // Checkmarks for state.
  .current-actions {
    li {
      &:hover {
        .png-icon-check {
          background-image: url(/images/icons/png/check_white.png);
        }
        .png-icon-loading {
          background-image: url(/images/icons/png/loading_white.png);
        }
      }

      .png-icon-check,
      .png-icon-loading {
        position: absolute;
        top: 0;
        left: 1em;
        height: 100%;
        transition: none;
      }

      .png-icon-loading {
        animation: spin 3s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.presence-bar__collaborator {
  position: relative;
  background: @color-white-dark;

  &:hover {
    background: @collaborator-hover-color;
  }

  &.focus .presence-bar__avatar {
    background: @collaborator-highlight-color;
  }

  &.menu--open {
    background: @color-gray;
  }
}

.current .presence-bar__collaborator {
  .last-activity {
    display: none;
  }
}

.presence-bar__avatar {
  position: relative;
  padding: @presence-bar-padding;
  cursor: pointer;

  img {
    width: @presence-bar-avatar-width;
    height: @presence-bar-avatar-width;
    vertical-align: middle;
  }
}

.offline .presence-bar__avatar {
  opacity: 0.3;
}

.presence-bar__badges {
  position: absolute;
  bottom: 0.4em;
  left: 0.4em;
  font-size: @font-size-m;
}

.presence-bar__badge {
  display: none;
  height: 1em;
  width: 1em;
  background-size: 100%;
  background-repeat: no-repeat;
}

.presence-bar__badge--active {
  display: inline-block;
}

.presence-bar__badge--moderator {
  background-image: url(/images/icons/badges/moderator.svg);
  color: @color-primary;
}

/*
Toggle Drawer

Markup:
<div style="position: relative; height: 100px; width: 80px; background-color: black">
  <div class="presence-bar__toggle-drawer">
    <span class="open-drawer">»</span>
    <span class="close-drawer">«</span>
  </div>
</div>

Styleguide: PresenceBar.ToggleDrawer
*/
.menu--open .presence-bar__toggle-drawer {
  .open-drawer {
    display: none;
  }
  .close-drawer {
    display: block;
  }
  transform: none;
}

.presence-bar__toggle-drawer {
  transform: translateX(-1px);
  position: absolute;
  top: -1px;
  left: 100%;
  width: @presence-bar-width;
  height: @row-width - 2px;
  line-height: @row-width;
  background: @menu-background-color;
  border: 1px solid @menu-border-color;
  border-left: none;
  box-shadow: 2px 0 0 fade(@color-black, 20%);
  text-align: center;
  font-size: 1.5rem;
  color: @color-gray-light;
  cursor: pointer;

  > span {
    width: 100%;
    height: 100%;
  }

  .open-drawer {
    display: block;
  }
  .close-drawer {
    display: none;
  }
}

.presence-bar__manage-collaborators {
  height: @row-width;
  min-height: @row-width;
  border-top: 2px solid @color-white;
  background-color: @color-white-off;
  background-image: url("/images/icons/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
  transition: background-color 300ms linear;

  .png-icon-locked--black {
    display: none;
  }

  &:hover:not(.presence-bar__manage-collaborators--disabled) {
    background-color: @color-gray-light;
    cursor: pointer;

    .tooltip {
      display: none;
    }
  }

  &.presence-bar__manage-collaborators--disabled {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.5;
    }

    .tooltip {
      width: 135px;
    }

    .png-icon-locked--black {
      display: block;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
}

/*
Presence Menu

Markup:
<div class="presence-bar">
  <div class="presence-bar__section current">
    <div class="online">
      <div class="presence-bar__collaborator current-collaborator">
        <div class="presence-bar__avatar">
          <img src="/images/avatar-ernie.jpg">
          <div class="presence-bar__badges">
            <div class="presence-bar__badge presence-bar__badge--moderator presence-bar__badge--active"></div>
          </div>
        </div>
        <div class="menu menu--minimalist menu--borderless menu--right menu--open menu--dropshadow">
          <div class="menu__content">
            <div class="presence-bar__user-info">
              <div class="display-name">Ernie Johnson</div>
              <div class="last-activity"><label>last activity: </label><span>online</span></div>
            </div>
            <div class="actions">
              <div class="presence-bar__toggle-drawer">
                <span class="open-drawer">»</span>
                <span class="close-drawer">«</span>
              </div>
              <ul class="menu__items current-actions">
                <li class="menu__item everyone-action download-csv" style="display: list-item;">Download Spreadsheet</li>
                <li class="menu__item incognito-mode toggle-on" style="display: list-item;">
                  Enable Incognito Mode
                </li>
                <li class="menu__item incognito-mode toggle-off" style="display: none;">
                  <i class="png-icon-check"></i>
                  Enable Incognito Mode
                </li>
                <li class="menu__item menu__item--border-top everyone-action logout" style="display: list-item;">Sign Out of Stickies</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

Styleguide: PresenceBar.Menu
*/

/*
Moderator Menu

Markup:
<div class="presence-bar">
   <div class="presence-bar__section current">
      <div class="online">
         <div class="presence-bar__collaborator current-collaborator">
            <div class="presence-bar__avatar">
               <img src="/images/avatar-ernie.jpg">
               <div class="presence-bar__badges">
                  <div class="presence-bar__badge presence-bar__badge--moderator presence-bar__badge--active"></div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="presence-bar__section moderators">
      <div class="online">
         <div class="presence-bar__collaborator other-collaborator">
            <div class="presence-bar__avatar">
               <img src="/images/avatar-bert.jpg">
               <div class="presence-bar__badges">
                  <div class="presence-bar__badge presence-bar__badge--moderator"></div>
               </div>
            </div>
            <div class="menu menu--right menu--minimalist menu--borderless menu--dropshadow menu--open">
              <div class="menu__content">
                <div class="presence-bar__user-info">
                  <div class="display-name">Bert Bacharach</div>
                  <div class="last-activity"><label>last activity: </label><span>10/2/2015</span></div>
                </div>
                <div class="actions">
                  <div class="close-drawer close-drawer--small">×</div>
                  <ul class="menu__items other-actions">
                    <li class="menu__item sticky-focus toggle-on" style="display: list-item;">
                      <i class="png-icon-focus png-icon-focus--white"></i>
                      Focus My Stickies
                    </li>
                    <li class="menu__item sticky-focus toggle-off" style="display: none;">
                      <i class="png-icon-focus png-icon-focus--white"></i>
                      Remove Focus
                    </li>
                    <li class="menu__item role set-moderator" style="display: list-item;">
                      <i class="png-icon-moderator png-icon-moderator--white"></i>
                      Make Moderator
                    </li>
                    <li class="menu__item role remove-moderator" style="display: none;">
                      <i class="png-icon-moderator png-icon-moderator--white"></i>
                      Remove Moderator
                    </li>
                  </ul>
                </div>
              </div>
            </div>
         </div>
      </div>
      <div class="offline"></div>
         </div>
      </div>
   </div>
</div>

Styleguide: PresenceBar.ModeratorMenu
*/

/*
Counter

Markup:
<div class="presence-bar__counter" style="position: relative">
  <div class="online">
    <div class="icon"></div>
    <div class="counter">93</div>
  </div>
  <div class="offline">
    <div class="icon"></div>
    <div class="counter">32</div>
  </div>
</div>

Styleguide: PresenceBar.Counter
*/
.presence-bar__counter {
  border-top: 2px solid @color-white;
  width: @row-width;
  display: flex;
  flex-direction: row;
  background-color: @color-white-dark;
  min-height: 25px;

  .online,
  .offline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    .icon {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      border: 1px solid @color-gray-light;
    }
    .counter {
      padding-left: 2px;
    }
  }

  .online .icon {
    background-color: @color-turquoise;
    border: 1px solid @color-turquoise-dark;
  }
}
