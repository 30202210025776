/*
Mega Text Field

A big form field with a large CTA button.

Markup:
<div class="mega-text">
  <input type="text" class="mega-text__input" placeholder="My new sticky board" />
  <button class="button button--primary mega-text__button">
    <i class="icon-add icon-size-big"></i> Add Board
  </button>
</div>

Styleguide: MegaText
*/

@centering-gutter: 2rem;

.mega-text {
  flex: 1;
  display: flex;
  padding-top: @vertical-rhythm;
  padding-bottom: @vertical-rhythm;
  max-width: 60rem;
  padding-left: @centering-gutter;
  padding-right: @centering-gutter;

  form {
    margin: 0;
  }
}

.mega-text__input-wrapper {
  position: relative;
  display: inline-block;
  flex: 3 0 0;
  margin-right: 0.5rem;

  .mega-text__input {
    width: 100%;
    height: 100%;
  }

  .button--thin {
    position: absolute;
    right: 3px;
  }
}

input.mega-text__input[type="text"] {
  flex: 3 0 0;
  font-size: @font-size-m;
  padding: 0.5rem 0.8rem;
  color: @color-gray-dark;
  background-color: @color-white;
  border-radius: @border-radius-base;
  border: 1px solid @color-gray;
  margin-right: 0.5rem;

  .outlined-input;

  &:focus {
    color: @color-gray-dark;
  }

  &::placeholder {
    font-style: italic;
    color: @color-gray-dark;
  }
}

.mega-text__button {
  flex: 0 1 8rem;
  padding: 0.5rem;
  line-height: normal;
  font-size: @font-size-s;

  @media @screen-s {
    padding-top: 0.7rem;
    padding-right: 0.8rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
  }
}
