/*
Sheet State Action Bar

`sheet-state--state-blur`: Makes the action bar visible

`sheet-state--state-normal`: Hides the action bar

`sheet-state--role-moderator`: Green moderator color

`sheet-state--role-<other>`: Grey color

Markup:
<div class="sheet-state sheet-state--role-moderator sheet-state--state-blur">
  <div class="action exit-incognito">
    <i class="png-icon-incognito png-icon-incognito--white"></i>
    Exit Incognito Mode
  </div>
</div>
<hr />
<div class="sheet-state state-blur sheet-state--state-blur">
  <i class="png-icon-incognito png-icon-incognito--white"></i>
  This sheet is in Incognito Mode
</div>

Styleguide: SheetStateActionBar
*/

#kssref-sheetstateactionbar .sheet-state {
  position: static;
}

#clipboard-event-anchor {
  width: 0;
  height: 0;
  position: fixed;
  border: none;
}

@state-bar-height: 60px;
@state-bar-offset: 44px;
@state-bar-horizontal-offset: 24px;
.sheet-state {
  display: none;
  position: fixed;
  top: @state-bar-offset;
  left: @state-bar-horizontal-offset;
  right: 0;
  height: @state-bar-height;
  line-height: @state-bar-height;
  text-align: center;
  color: @color-white;
  font-size: @font-size-l;
  font-weight: bold;

  div {
    display: inline;
    padding: 0.25em 1em;

    &.action {
      cursor: pointer;
    }
  }
}

.sheet-state--state-blur {
  display: block;
  background: @color-gray-light;
}

.sheet-state--state-reload,
.sheet-state--state-reload.sheet-state--role-moderator,
.sheet-state--state-stale,
.sheet-state--state-stale.sheet-state--role-moderator {
  display: block;
  background: @color-red-light;
  a {
    color: @color-white;
  }
}

.sheet-state--role-moderator {
  background: @color-turquoise;
}
