/*
Buttons

Markup:
<button class="button {{modifier_class}}">Basic Button</button>
<button class="button {{modifier_class}}" disabled="disabled">Disabled Button</button>
<button class="button button--mini {{modifier_class}}">Mini Button</button>

.button--primary  - Primary button
.button--subtle   - Subtle, no-bg button
.button--google   - Google colors
.button--twitter  - Twitter colors
.button--facebook - Facebook colors

Styleguide Buttons
*/

.button-template(@border, @normal, @active) {
  background-color: @normal;
  border: 1pt solid @border;
  color: @active;
  text-decoration: none;

  &:hover {
    background-color: @active;
    color: @normal;

    &[disabled] {
      background-color: @normal;
      color: @active;
    }
  }

  &[disabled] {
    opacity: 0.75;
  }
}

.button {
  .button-template(@color-gray-dark, @color-white, @color-gray-dark);

  display: inline-block;
  // padding: @line-height-half;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  border-radius: @input-radius-border;
  font-size: @font-size-m;
  cursor: pointer;
  transition: border-color @transition-duration, background-color @transition-duration, color @transition-duration;

  &[disabled] {
    cursor: not-allowed;
  }
}

.button--primary {
  .button-template(@color-primary, @color-primary, @color-white);
}

.button--secondary {
  .button-template(@color-primary, @color-white, @color-primary);
  font-size: @font-size-s;
}

.button--full {
  width: 100%;
}

.button--half {
  width: 50%;
}

.button--half-spaced {
  width: 48%;
  margin: 0 1%;
}

.button--subtle {
  background-color: transparent;
  color: @color-gray;
  border-color: @color-gray;

  &:hover {
    background-color: @color-gray;
    color: @color-white;
  }
}

.button--google {
  .button-template(@color-google, @color-google, @color-white);
}

.button--twitter {
  .button-template(@color-twitter, @color-twitter, @color-white);
}

.button--facebook {
  .button-template(@color-facebook, @color-facebook, @color-white);
}

.button--features {
  .button-template(@color-accent, @color-white, @color-accent);

  font-family: futura-pt-bold, sans-serif;
  font-size: 1.5rem;
  border-radius: 1.25rem;
  border-width: 2px;
  letter-spacing: 1px;
  width: 16.19rem;
  text-align: center;
}

.button--thin {
  position: relative;
  top: 3px;
  height: calc(~"100% - 6px");
  line-height: 100%;
  font-size: 1rem;
  padding: 0 16px;
}

.button--mini {
  padding: 0.2rem 0.4rem;
  font-size: 0.6rem;
}

// 2020 Stickies redesign
.button--cta {
  background-color: @color-accent;
  color: @color-white;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.4rem;
  border: 2px solid @color-accent;
  border-radius: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 0.3rem 2.8rem;
  filter: drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.25));

  &:hover {
    background-color: @color-white;
    color: @color-accent;
  }
}

.button--line {
  background-color: transparent;
  color: @color-deep;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.4rem;
  border-width: 2px;
  border-radius: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 0.3rem 2.8rem;

  &:hover {
    background-color: @color-deep;
    color: @color-white;
  }
}
