.board {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  padding-top: 44px;
  padding-left: 80px;
  user-select: none;
  box-sizing: content-box;
}
