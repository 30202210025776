.help-layout {
  .text-hero {
    font-size: 1.2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 0;

    @media @screen-m {
      font-size: 2rem;
    }
  }
}

.help-layout__main {
  display: flex;
}

.help-layout__main-content {
  display: flex;
  justify-content: space-between;
  margin-top: 1.1rem;
  max-width: 100rem;

  .page-content-help {
    flex: 4 4 0%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 4rem;

    @media @screen-m {
      font-size: 2rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    div.section {
      font-family: @font-family-base;
      line-height: 2em;
      font-size: 1rem;
      font-weight: 200;

      a.anchor {
        display: block;
        position: relative;
        top: -2.75rem;
        visibility: hidden;
      }

      h2 {
        border-bottom: 1px solid @grayMid;
      }
      h3 {
        margin-bottom: 0.3rem;
      }
      img {
        width: 100%;
        padding: 2rem 0;
      }
    }
    .green,
    .blue,
    .orange {
      font-weight: 500;
      padding: 0.3em;
    }
    .green {
      background-color: @color-green;
    }
    .blue {
      background-color: @color-blue;
    }
    .orange {
      background-color: @color-orange;
    }
  }
}

.help-layout__side-nav {
  display: none;

  @media @screen-m {
    flex: 0 1 30rem;
    height: auto;
    align-self: flex-start;
    display: block;
    position: sticky;
    top: 2.5rem;
    padding: 0 1rem;
    background-color: @color-white-off;
    font-family: @font-family-sans;
    font-size: 0.7rem;
  }
}

.help-layout__side-nav-links {
  li {
    list-style: none;
    margin-top: 0.8rem;

    &.subcategory {
      margin-left: 1rem;
      margin-top: 0.2rem;
    }

    &:last-of-type {
      margin-bottom: 0.8rem;
    }
  }

  li a {
    font-weight: 500;
    color: @color-gray;
    text-decoration: none;

    &.active {
      color: @color-black;
      font-weight: 600;
    }
  }
}

.help-layout--redesign {
  .text-hero {
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    background-color: @color-white;
  }

  .text-hero__heading {
    font-family: futura-pt-bold, sans-serif;
    text-align: left;
    margin: 0 12%;
  }

  .help-layout__main {
    margin: 0 12%;
  }

  h2,
  h3,
  h4 {
    font-family: futura-pt-bold, sans-serif;
  }

  .help-layout__main-content .page-content-help div.section {
    font-family: futura-pt, sans-serif;
  }

  .help-layout__side-nav {
    font-family: futura-pt-bold, sans-serif;
    font-size: 1rem;

    & .subcategory {
      font-family: futura-pt, sans-serif;
      margin-left: 1rem;
      margin-top: 0.2rem;
    }

    @media @screen-m {
      top: 0;
    }
  }

  .page-content-help {
    padding-right: 0;

    @media @screen-m {
      padding-right: 0;
    }
  }
}
