/*
Behaviors & animations

Behavioral CSS properties.

Styleguide: Behaviors
*/

/*
Collapsing/expanding accordion

Tag items with `collapse`. Add `in` class to open, remove to close.

Markup:
<div class="collapse in">I am collapsing</div>
<script>
  setTimeout(function() {
    var els = document.getElementsByClassName("collapse");
    els[0].className = "collapse"
  }, 2000)
</script>

Styleguide: Behaviors.Accordion
*/

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
  &.in {
    height: auto;
  }
}

.immediate {
  transition: none !important;
}
