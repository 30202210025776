/*
Hero

Markup:
<div class="hero" style="background-image: url(/images/backgrounds/sign-up.jpg);">
  <div class="container">
    <h3 class="hero__heading">
      Collaborate with
      <div class="logo stickies-logo"></div>
    </h3>
    <div class="hero__button-group">
      <button class="show-social-login button button--primary">Sign up free</button>
      <button class="show-social-login button">Log in</button>
    </div>
  </div>
  <div class="hero__subheading open">
    <div class="container">
      <h4 class="login__call-to-action">Sign in with</h4>
      <div class="hero__button-group">
        <button id="facebook" class="button button--facebook hero__button login__button"><i class="icon-facebook"></i><span>facebook</span></button>
        <button id="google"   class="button button--google hero__button login__button"><i class="icon-google"></i><span>google</span></button>
        <button id="twitter"  class="button button--twitter hero__button login__button"><i class="icon-twitter"></i><span>twitter</span></button>
      </div>
    </div>
  </div>
</div>

Styleguide: Hero
*/

.hero {
  display: flex;
  flex-wrap: wrap;
}

.hero__heading {
  flex: 0 0 100%;
  color: @color-white;
}

.hero__button-group {
  flex: 0 0 100%;

  .button + .button {
    margin-left: 0.5rem;
  }
}

.hero__subheading {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;

  margin-top: 3rem;
  max-height: 0px;
  transition: max-height 0.4s;
  background: rgba(0, 0, 0, 0.3);
  overflow-y: hidden;

  &.open {
    max-height: 200px;
  }
}

.hero > .container,
.hero__subheading > .container {
  flex: 0 0 100%;
}

.login__call-to-action {
  margin-top: 2rem;
  flex: 0 0 100%;
  color: #fff;
}

.hero__button {
  flex: 1;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: @vertical-rhythm;

  &:hover {
    background-color: white;
  }

  i {
    font-size: 20px;
    padding-right: 6px;
    line-height: 22px;
  }
}
