/*
Avatar

An image representing a user's image avatar.

avatar--medium - Medium 36px
avatar--large  - Large 48px

Markup:
<img class="avatar {{modifier_class}}" src="/images/avatar-bert.jpg" title="Bert Bacharach">
<img class="avatar {{modifier_class}}" src="/images/avatar-ernie.jpg" title="Bert Bacharach">

Styleguide: Avatar
*/
.avatar {
  max-height: 22px;
  max-width: 22px;
  height: auto;
  margin-right: 0.25rem;
}

.avatar--medium {
  max-height: 36px;
  max-width: 36px;
}

.avatar--large {
  max-height: 48px;
  max-width: 48px;
}
