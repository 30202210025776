/*
Header

Top black bar with navigational content.

Markup:
<div class="header"></div>
<hr />
<div style="background-image: url(/images/backgrounds/sign-up.jpg);">
  <div class="header header--clear"></div>
</div>

Styleguide Header
*/

@header-height: 44px;
@header-control-padding-right: 0.7em;
@header-control-padding-left: 0.5em;
@header-control-editable-icon-size: 24px;
.kss-modifier__example .header {
  position: static;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  z-index: 9999;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: @header-height;

  background-color: @color-black;
}

.header--clear {
  background-color: transparent;
}

/*
Header logo

Markup:
<div class="header">
  <div class="logo header__logo"></div>
</div>
<hr />
<div class="header">
  <div class="logo header__logo header__logo--centered"></div>
</div>
<hr />
<div class="header">
  <div class="logo header__logo header__logo--large"></div>
</div>

Styleguide Header.Logo
*/

.header__logo {
  height: @header-height;
  background-position: center;
}

.header__logo--centered {
  margin: 0 auto;
}

.header__logo--large {
  height: @header-height * 2;
  width: 13rem;
  background-size: 85%;
}

/*
Header controls

Markup:
<div class="header">
  <div class="header__control"></div>
</div>
<hr />
<div class="header">
  <div class="header__control"></div>
  <div class="header__control"></div>
</div>
<hr />
<div class="header">
  <div class="header__control"></div>
  <div class="header__control"></div>
  <div class="header__control"></div>
</div>
<hr />
<div class="header">
  <div class="header__control">
    Align me to the left
  </div>
  <div class="header__control header__control--right">
    Align me to the right
  </div>
</div>
<hr />
<div class="header">
  <div class="logo header__logo"></div>
  <div class="header__control"></div>
</div>
<hr />
<div class="header">
  <div class="logo header__logo header__logo--large"></div>
  <div class="header__control"></div>
</div>

Styleguide Header.Controls
*/

#kssref-header-controls .kss-modifier__example .header__control {
  background-color: @color-red;
}

.header__control {
  flex: 1 0 0%;
  position: relative;
  box-sizing: border-box;
  padding: 0 @header-control-padding-right 0 @header-control-padding-left;
  margin: 0.5em 0;
  border-right: 1px solid @color-gray;
  max-width: 14rem;

  &:last-child {
    border-right: none;
  }
}

.header__control--wide {
  flex: 2 0 0%;
  max-width: 25rem;
}

.header__control--right {
  order: 1;
  text-align: right;
  max-width: none;
}

/*
Header inputs

Markup:
<div class="header">
  <div class="header__control">
    <div class="header__input">Uneditable Input</div>
  </div>
</div>
<hr />
<div class="header">
  <div class="header__control">
    <input class="header__input header__input--editable" placeholder="Sample Text" />
  </div>
</div>
<hr />
<div class="header">
  <div class="header__control">
    <input class="header__input header__input--editable" placeholder="Left Input" />
  </div>
  <div class="header__control">
    <input class="header__input header__input--editable" placeholder="Right Input" />
  </div>
</div>
<hr />
<div class="header">
  <div class="header__control">
    <input
      class="header__input header__input--editable"
      value="This is a bunch of text that is going to push the input over the edge as far as content" />
  </div>
  <div class="header__control">
    <input class="header__input header__input--editable" placeholder="Second Input" />
  </div>
  <div class="header__control">
    <input class="header__input header__input--editable" placeholder="Third Input" />
  </div>
</div>
<hr />
<div class="header">
  <div class="header__control header__control--wide">
    <input class="header__input header__input--editable" placeholder="Sample Wide Text" />
  </div>
</div>

Styleguide Header.Inputs
*/

.header__input {
  color: @color-gray;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid @color-gray;
  width: 100%;
  font-smoothing: antialiased;
  text-overflow: ellipsis;
  font-weight: 200;

  &:placeholder {
    color: @color-gray-light;
  }

  i {
    vertical-align: baseline;
  }
}

.header__input--editable {
  color: @color-white;

  &:focus,
  &:hover {
    background-image: url("/images/icon-pencil.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 50%;
  }
}

/*
Header Clickable Menu

Control that when clicked will drop open a menu.

Markup:
<div class="header">
  <div id="sheet-list" class="header__control">
    <input type="text" class="sheet-name header__input header__input--clickable"
           autocomplete="off" placeholder="clickable menu">
    <div class="header__menu-toggle"></div>
  </div>
</div>

Styleguide: Header.ClickableMenu
*/

.header__input--clickable {
  color: @color-white;
  cursor: pointer;
}

.header__input--fixed {
  width: 400px;
}

/*
Header action link

Markup:
<div class="header">
  <div class="header__control">
    <div class="header__action-link">
      <a href="#"><i class="icon-logout"></i>Sign Out</a>
    </div>
  </div>
</div>

Styleguide Header.ActionLink
*/

.header__action-link {
  font-weight: bold;
  display: inline-block;

  i {
    margin: 0 0.5em 0.25em 0;
  }

  a {
    padding: 0.5em;
    padding-right: 1em;
    text-decoration: none;
    color: @color-white;
    -webkit-font-smoothing: auto;
  }
}

.header__action-link--right {
  a {
    padding-right: 0;
  }
}

.header__small-action {
  display: inline-block;
  cursor: pointer;
  width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.header__small-action--disabled {
    cursor: auto;
  }

  &.board-access--public {
    background-image: url("/images/icons/png/unlocked_white.png");
  }

  &.board-access--private {
    background-image: url("/images/icons/png/locked_white.png");
  }

  &.board-access--disabled {
    cursor: default;
  }
}

/*
Header Editable Menu

Input field + toggle styles. To see flyout menu styles, see the [Menu](section-menu.html) module.

Markup:
<div class="header">
  <div id="sheet-list" class="header__control">
    <input type="text" class="sheet-name header__input header__input--editable header__editable-menu"
           autocomplete="off" placeholder="when menu closed">
    <div class="header__menu-toggle"></div>
  </div>
  <div id="sheet-list" class="header__control header__control--expanded">
    <input type="text" class="sheet-name header__input header__input--editable header__editable-menu"
           autocomplete="off" placeholder="when menu open">
    <div class="header__menu-toggle"></div>
  </div>
</div>

Styleguide: Header.MenuToggle
*/

.header__editable-menu {
  width: 100%;

  &:hover,
  &:focus {
    background-position: right @header-control-editable-icon-size center;
  }
}

.header__menu-toggle {
  background-image: url("/images/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  width: @header-control-editable-icon-size;
  height: @header-control-editable-icon-size;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: @header-control-padding-right;
  width: 20px;
}

.header__menu-toggle--disabled {
  cursor: auto;
}

.header__control--expanded {
  .header__menu-toggle {
    background-image: url("/images/icons/arrow-up.svg");
  }
}
