.add-board {
  background-color: @color-white-dark;
  display: flex;
  justify-content: center;

  i.icon-lock {
    display: inline-block;
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 0.5rem;
  }

  #board-access-toggle {
    width: 154px;
    text-align: center;
    background-color: white;
    font-size: 0.85rem;
    &:hover {
      background-color: #afb2b3;
    }
  }

  #board-access[value="public"] ~ .mega-text__input-wrapper #board-access-toggle {
    i.icon-lock {
      background-image: url("/images/public--black.svg");
    }
    .button-text:after {
      content: "Public Board";
    }

    //&:hover {
    //  i.icon-lock { background-image: url('/images/private--white.svg'); }
    //  .button-text:after { content: 'Make Private' }
    //}
  }

  #board-access[value="private"] ~ .mega-text__input-wrapper #board-access-toggle {
    i.icon-lock {
      background-image: url("/images/private--black.svg");
    }
    .button-text:after {
      content: "Private Board";
    }

    //&:hover {
    //  i.icon-lock { background-image: url('/images/public--white.svg'); }
    //  .button-text:after { content: 'Make Public' }
    //}
  }
}
