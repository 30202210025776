/*
Tooltips

Hover tooltips to explain important UI elements on the screen. The class names
refer to the placement of the tooltip in relation to the element its describing.
For example, `tooltip--above` should appear above the element.

Markup:
<span class="tooltip">
  Tooltip
</span>
<br>
<br>
<span class="tooltip tooltip--above">
  Above
</span>
<br>
<br>
<span class="tooltip tooltip--below">
  Below
</span>
<br>
<br>
<span class="tooltip tooltip--left">
  Left
</span>
<br>
<br>
<span class="tooltip tooltip--right">
  Right
</span>
<br>
<br>
<span class="tooltip tooltip--above-left">
  Above Left
</span>

Styleguide: Tooltips
*/

@tooltip-triangle-size: 10px;
@tooltip-padding: 10px;
@tooltip-color: @color-gray-dark;
@tooltip-font-size: @font-size-xs;

.tooltip {
  color: @color-white;
  background-color: @tooltip-color;
  border-radius: @border-radius-base;
  display: inline-block;
  font-family: @font-family-base;
  font-size: @tooltip-font-size;
  padding: @tooltip-padding;

  &:after {
    border: @tooltip-triangle-size solid transparent;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
}

.tooltip--above {
  left: 50%;
  bottom: 100%;
  margin-bottom: 1.5 * @tooltip-triangle-size;
  transform: translateX(-50%);

  &:after {
    border-top-color: @tooltip-color;
    bottom: -2 * @tooltip-triangle-size;
    left: 50%;
    margin-left: -@tooltip-triangle-size;
  }
}

.tooltip--below {
  top: 100%;
  margin-top: 1.5 * @tooltip-triangle-size;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    border-bottom-color: @tooltip-color;
    top: -2 * @tooltip-triangle-size;
    left: 50%;
    margin-left: -@tooltip-triangle-size;
  }
}

.tooltip--left {
  right: 100%;
  margin-right: 1.5 * @tooltip-triangle-size;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    border-left-color: @tooltip-color;
    right: -2 * @tooltip-triangle-size;
    margin-top: -@tooltip-triangle-size;
    top: 50%;
  }
}

.tooltip--right {
  left: 100%;
  margin-left: 1.5 * @tooltip-triangle-size;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    border-right-color: @tooltip-color;
    left: -2 * @tooltip-triangle-size;
    margin-top: -@tooltip-triangle-size;
    top: 50%;
  }
}

.tooltip--above-left {
  left: 5%;
  bottom: 100%;
  margin-bottom: 1.5 * @tooltip-triangle-size;

  &:after {
    border-top-color: @tooltip-color;
    bottom: -2 * @tooltip-triangle-size;
    left: 13px;
    margin-left: -@tooltip-triangle-size;
  }
}

/*
Tooltips in context

To create the hover effect, include `span.tooltip` within a parent element
that has the class `.tooltip--parent`. The parent element must have a
`position` that is NOT `static`.

Markup:
<div class="tooltip-parent" style="background-color: red;">
  <span class="tooltip tooltip--above">
    Above
  </span>
</div>
<div class="tooltip-parent" style="background-color: red;">
  <span class="tooltip tooltip--below">
    Below
  </span>
</div>
<div class="tooltip-parent" style="background-color: red;">
  <span class="tooltip tooltip--left">
    Left
  </span>
</div>
<div class="tooltip-parent" style="background-color: red;">
  <span class="tooltip tooltip--right">
    Right
  </span>
</div>
<div class="tooltip-parent" style="background-color: red;">
  <span class="tooltip tooltip--above-left">
    Above Left
  </span>
</div>

Styleguide: Tooltips.InContext
*/

#kssref-tooltips-incontext {
  .tooltip-parent {
    height: 30px;
    margin: 50px;
    width: 30px;
  }
}

.tooltip-parent {
  position: relative;

  .tooltip {
    display: none;
    position: absolute;
    z-index: 9999; // we need to deal with this
  }

  &:hover {
    .tooltip {
      display: inline-block;
      opacity: 1;
      animation: tooltip-appear 0.5s linear 1;
    }
  }
}

@keyframes tooltip-appear {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    display: inline-block;
  }
}

/**
Card Tooltip

Hover tooltip, showing card +1 avatars.

Markup:
<div class="footer card__footer">
  <div class="card__plus-one--button card__plus-one">
    <div class="card__plus-one-count">-1</div>
  </div>
  <div class="card__plus-one--static-text card__plus-one card__plus-one--hovered tooltip-parent">
    <div class="card__plus-one-count">+1</div>
    <span class="tooltip tooltip--right">
      <div class="tooltip__avatars {{modifier_class}}">
        <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
        <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
        <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
        <img class="avatar" src="/images/avatar-ernie.jpg" title="Ernie Johnson">
      </div>
    </span>
  </div>
</div>
<br>
<br>
<br>
<br>

.tooltip__avatars--1  - Single column
.tooltip__avatars--2  - Double column
.tooltip__avatars--3  - Triple column

Styleguide: Tooltips.CardTooltip
*/

@avatar-width: 22px;
@avatar-width-with-border: 26px;
@avatar-spacing: 0.5em;
.tooltip__avatars {
  .avatar {
    border: 2px solid @color-white-dark;
    box-sizing: content-box;
    display: block;
    float: left;
    margin: 0 @avatar-spacing 0 0;
  }

  &--1 {
    width: @avatar-width-with-border;

    .avatar {
      margin-right: 0;
    }
  }
  &--2 {
    width: calc(2 * @avatar-width-with-border ~"+ @{avatar-spacing}");

    .avatar:nth-child(even) {
      margin-right: 0;
    }
  }
  &--3 {
    width: calc(3 * @avatar-width-with-border + ~"+ 2 * @{avatar-spacing}");

    .avatar {
      margin-top: @avatar-spacing;

      &:nth-child(-n + 3) {
        margin-top: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

#kssref-tooltips-cardtooltip {
  // Show tooltip by default for demo
  .tooltip-parent {
    .tooltip {
      display: inline-block;
      opacity: 1;
    }

    &:hover {
      .tooltip {
        animation: none;
      }
    }
  }
}
