.user-form__button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-form__button-group-extra {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.user-form__extras-link {
  margin-top: 0.5rem;
  font-size: 16px;
}

.user-form__button {
  border-radius: 10px;
  font-family: futura-pt-bold, sans-serif;
  letter-spacing: 1px;
  font-size: 24px;
  margin-bottom: 1rem;

  &.button--secondary {
    font-size: 12px;
  }

  &.button--primary#google {
    border: none;
    background: url("/images/social/sign-in-with-google.png") no-repeat center;
    width: 191px;
    height: 46px;

    &:hover {
      background-image: url("/images/social/sign-in-with-google-hover.png");
    }

    span {
      visibility: hidden;
    }
  }
}

.user-form__container {
  min-height: 40rem;
  display: flex;
  flex: 1 0 auto;
  font-family: futura-pt, sans-serif;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url(/images/backgrounds/form-rectangle.svg);
  background-repeat: no-repeat;
  background-position: bottom -93px right 0px;
  background-size: 1600px 250px;
  color: @color-deep;

  @media @screen-m {
    flex-direction: row;
  }

  @media @screen-xl {
    background-position: bottom -210px right 0px;
    background-size: calc(2 * 1600px) calc(2 * 250px);
  }
}

.user-form__container--authenticated {
  background-image: none;
  margin-top: 154px;
}

.user-form__column {
  flex-grow: 1;
  display: none;

  @media @screen-s {
    display: block;
  }
}

.user-form__main-column {
  display: flex;
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;

  @media @screen-xs {
    padding-right: 0;
    padding-left: 0;
  }
}

.user-form__headline {
  font-family: futura-pt-bold, sans-serif;
  margin-bottom: 3rem;
  margin-top: 0;
  line-height: 1em;
  font-size: 1.7rem;
  text-align: center;
  color: @color-deep;
}

.user-form__message {
  text-align: center;
  height: 2.5rem;
  display: none;
}

.user-form__message--error {
  color: @color-accent;
}

.user-form__message--confirmation {
  color: @color-positive;
}

.user-form__form {
  margin-bottom: 2rem;
}

.user-form__filler {
  flex: 1;
}

.user-form__headline--accent {
  color: @color-positive;
}

.user-form__input-container {
  display: flex;
}

.user-form__input-label {
  width: auto;
}

.user-form__input-sub-label {
  padding-left: 1em;
  flex: 1;
}

.user-form__forgot-password {
  margin-top: 2em;
}
