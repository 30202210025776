/*
Onboarding: Create Sticky

Onboarding users who have not created a sticky.

Markup:
<div id="onboarding">
  <div class="onboarding__create-sticky-flow">
    <div class='wrapper'>
      <div class='icon'></div>
      <div class='message'>
        <div>Double click anywhere on the board</div>
        <div>to create a new Sticky.</div>
      </div>
      <button class='dismiss button button--primary'>Ok, got it!</button>
    </div>
  </div>
</div>

Styleguide: Onboarding.CreateSticky
*/

.kss-section {
  #onboarding {
    position: relative;
  }
  #onboarding .onboarding__create-sticky-flow {
    position: inherit;
  }
}

#onboarding {
  position: fixed;
  top: 0;
}

.onboarding__create-sticky-flow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: fade(black, 75%);

  .wrapper {
    text-align: center;
    align-items: center;
    border: medium dashed @color-white;
    border-radius: 5px;
    box-shadow: 0px 3px 5px @color-black;
    padding: 88px 150px 88px 150px;

    .icon {
      background-image: url("/images/icon-cards.png");
      width: 116px;
      height: 80px;
      margin: auto;
      margin-bottom: 40px;
    }

    .message {
      text-align: center;
      font-size: 36px;
      font-weight: lighter;
      color: @color-white;
    }

    .button--primary {
      margin-top: 40px;
      padding: 15px 35px 15px 35px;
      font-size: 20px;
      font-weight: lighter;
      box-shadow: 0px 3px 5px @color-black;
    }
  }
}

/*
Onboarding: Create Sheet

Onboarding users who have not created a sticky.

Markup:
<div id="onboarding">
  <div class="onboarding__tooltip onboarding__create-sheet-flow">
    <div class="onboard-dot"></div>
    <div class="guide-tooltip">
      <div class="wrapper">
          <div class="message">Add a new sheet to your board here.</div>
          <button class="dismiss button button--primary">Ok, got it!</button>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>

Styleguide: Onboarding.CreateSheet
*/
.onboarding__tooltip {
  position: absolute;

  .dismiss {
    margin-top: 20px;
  }
}

.onboard-dot {
  background-color: @color-red;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 1.5px rgba(255, 255, 255, 1), 1px 1px rgba(0, 0, 0, 0.3);
  width: 12px;
  height: 12px;
  position: absolute;
  animation: pulse 1s infinite alternate ease-in;
  cursor: pointer;
  &:hover {
    animation: none;
  }
  &--center {
    top: -30px;
    left: 194px;
  }
  &--low-left {
    top: auto;
    left: -30px;
    bottom: 29px;
  }
  &--upper-left {
    top: 28px;
    left: -30px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes colorPulse {
  0% {
    background-color: @color-red;
  }
  100% {
    background-color: @color-google;
  }
}

/*
Onboarding: Add Collaborators

Onboarding users who have not added a collaborator

Markup:
<div id="onboarding">
  <div class="onboarding__tooltip">
    <div class="onboard-dot onboard-dot--low-left"></div>
    <div class="guide-tooltip guide-tooltip--low-left">
      <div class="wrapper">
          <div class="message">Add 'em</div>
          <button class="dismiss button button--primary">Ok, got it!</button>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>

Styleguide: Onboarding.AddCollaborators
*/

/*
Onboarding: Drawer

Onboarding users who have not opened the drawer

Markup:
<div id="onboarding">
  <div class="onboarding__tooltip">
    <div class="onboard-dot onboard-dot--upper-left"></div>
    <div class="guide-tooltip guide-tooltip--upper-left">
      <div class="wrapper">
          <div class="message">Open it!</div>
          <button class="dismiss button button--primary">Sure will</button>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>

Styleguide: Onboarding.Drawer
*/
