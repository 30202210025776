/*
Dashboard

Styles for dashboard views of boards and collections thereof.

Styleguide: Dashboard
*/

/*
Dashboard Grid

A containing grid for board cells. The layout responsively scales to the viewport width.

Markup:
<div class="board-grid">
  <div class="board-grid-header">
    <h4 class="board-grid-header__title">My boards
      <span class="board-grid-header__count">(4)</span>
      <span class="board-grid-header__expand"><button class="button button--mini button--subtle">SHOW</button></span>
    </h4>
  </div>
  <div class="board-cells">
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
    <div class="board-cell"></div>
  </div>
</div>

Styleguide: Dashboard.Grid
*/

.board-grid {
  margin-top: @vertical-rhythm;
  @media @screen-m {
    padding-left: 4rem;
  }
  @media @screen-l {
    padding-left: 0;
  }
}

.board-cells {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;

  @media @screen-s {
    justify-content: flex-start;
    padding-left: 4em;
  }
  @media @screen-l {
    padding-left: 0;
    padding-right: 0;
  }
}

.board-grid-header {
  display: flex;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: @vertical-rhythm;
  border-bottom: 1px solid @color-gray-light;

  @media @screen-m {
    max-width: 61rem;
  }
  @media @screen-l {
    max-width: none;
  }
}

.board-grid-header__count {
  color: @color-gray;
  font-weight: normal;
}

.board-grid-header__expand {
  flex: 1;
  align-self: flex-end;
  text-align: right;
}

.board-grid-header__title {
  margin-bottom: 0;
  flex: 3;
  align-self: flex-end;
}
