/*
Logo

The stickies logo!

Markup:
<div style="background-color: black">
  <div class="logo"></div>
</div>

Styleguide: Logo
*/

.logo {
  background-image: url("/images/icons/stickies-logo.svg");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 100%;

  display: block;
  text-decoration: none;
  width: 11rem;
  background-size: 65%;
  height: 5em;
}

.logo-mask {
  mask: url("/images/icons/stickies-logo.svg") no-repeat center left;
  mask-size: 65%;
  background-color: white;

  display: block;
  text-decoration: none;
  width: 11rem;
  height: 5em;
}

.logo-mask--larger {
  width: 20rem;
}

.logo-mask--accent {
  background-color: @color-accent;
}
