// Stickies-specific layout styles
html {
  height: 100%;
  overflow-y: scroll;
}

body {
  height: 100%;
  width: 100%;
  min-height: 800px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.column-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: left;
  justify-content: center;
}

.sams-corner {
  max-height: 75%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  height: 386px;
  background-image: url("/images/sam-stickies.svg"), url("/images/icons/card-tilted-blue.svg");
  background-repeat: no-repeat;
  background-position: bottom 0 right 20%;
  display: block;
  text-decoration: none;
  position: relative;
  flex-grow: 1;

  @media @screen-m {
    margin-left: 2rem;
    margin-right: 1rem;
    width: auto;
  }
}

.sams-corner--green {
  background-image: url("/images/sam-stickies.svg"), url("/images/icons/card-tilted-green.svg");
}

.sams-corner--red {
  background-image: url("/images/sam-stickies.svg"), url("/images/icons/card-tilted-red.svg");
}

.nav-cell {
  font-family: futura-pt-bold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.75px;
  text-transform: capitalize;
  color: @color-deep;
  text-decoration: none;
}

.nav-cell__link {
  color: @color-deep;
  text-decoration: none;
  &:hover {
    color: @color-link-hover;
  }
}

.nav-cell__link--accent {
  color: @color-bright;
}

.nav-cell--light .nav-cell__link {
  color: @color-white;
  &:hover {
    color: @color-link-hover;
  }
}

.nav-cell--light .nav-cell__link--accent {
  color: @color-black;
}
