/*
Modal Dialogs (Legacy)

Markup:
<div id="modal"><div id="modal-container">
  <div id="modal-backdrop">
  </div>
  <div id="modal-box">
    <a class="close-button visible">×</a>
    <div class="title">Cool you found something new!</div>
    <div class="message">Private boards are a paid feature. Want to upgrade?</div>
    <ul class="buttons"><li><button class="button action a_upgrade-plan" data-index="0">Upgrade</button></li></ul>
  </div>
</div></div>

Styleguide: LegacyModal
*/

#kssref-legacymodal {
  position: relative;
  #modal {
    #modal-container,
    #modal-backdrop {
      position: absolute;
    }
  }
}

@modal-background-color: @color-white;
@modal-border-color: rgba(0, 0, 0, 0.2);
@modal-backdrop-background-color: rgba(0, 0, 0, 0.2);
@modal-text-color: @color-gray-dark;
@modal-padding: 11px 19px;
#modal {
  #modal-container,
  #modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
  }

  #modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 400000;
  }

  #modal-backdrop {
    background: @modal-backdrop-background-color;
    z-index: -1;
  }

  #modal-box {
    position: relative;
    max-width: 30em;
    background: @modal-background-color;
    border: 1px solid @modal-border-color;
    padding: 2rem;
    color: @modal-text-color;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    .close-button {
      position: absolute;
      top: 0.5rem;
      right: 0.75rem;
      font-weight: bold;
      font-size: 1.5rem;
      text-decoration: none;
      cursor: pointer;
      display: none;
      line-height: 1.5rem;
      color: @color-gray-light;

      &:hover {
        color: @color-gray-dark;
      }

      &.visible {
        display: block;
      }
    }

    .title {
      font-size: @font-size-l;
      line-height: 1.2em;
      padding: @modal-padding;
    }

    .message {
      padding: @modal-padding;
      padding-top: 0;

      &:empty {
        display: none;
      }
    }

    select {
      border: none;
      background-color: @color-white;
      width: 100%;
      outline: none;
      padding: 2px;
      border-radius: 0;

      option {
        padding: @modal-padding;
        background-color: @color-white;
        border-bottom: 1px solid fade(@modal-border-color, 10%);

        &:first-child {
          border-top: 1px solid fade(@modal-border-color, 10%);
        }

        &:hover {
          outline: 2px solid @redActive;
          border-color: transparent;
        }

        &:checked {
          outline: none;
        }
      }
    }

    .buttons {
      padding: @modal-padding;
      padding-bottom: 0;
      margin: 0;

      li {
        display: inline-block;
        padding: 0.25em 0;

        &:nth-child(n + 2) {
          padding-left: 1em;
        }

        &:last-child button {
          margin-right: 0;
        }
      }

      button {
        &.button {
          .button;
          padding: 0.75em 3em;
          margin-right: 1em;
        }

        &.normal {
          .button--subtle;
        }

        &.action {
          .button--primary;
        }
      }
    }
  }
}
