/*
Modal Dialog For Manage Collaborators (Legacy)

Markup:
<div id="modal">
  <div id="modal-container">
    <div id="modal-backdrop">
    </div>
    <div id="modal-box" class="modal-box--manage-collaborators">
      <a class="close-button visible">×</a>
      <div class="title">Manage Collaborators</div>
      <div class="message">Set who can participate and lead meetings on this board.</div>
      <ul class="buttons"></ul>
      <div class='body'>
        <div class='send-invites'>
          <div class='form__group'>
            <input class='form__control invitation-email' placeholder='Add user...'>
            <div class="collaborator-role-selector collaborator-role-selector--manage">
              <div class="collaborator-role-selector__current-role collaborator-role-selector__current-role--manage">
                <div class="collaborator-role-selector__current-role-name collaborator-role-selector__current-role-name--manage">moderator</div>
                <div class="collaborator-role-selector__menu-toggle"></div>
              </div>
              <div class="menu menu--within-manage-collaborators menu--down menu--open">
                <div class="menu__content">
                  <ul class="menu__items"><li class="menu__item menu__item--active menu__item--manage">editor</li></ul>
                </div>
              </div>
            </div>
            <button class='form__button button--primary send-email'>Send</button>
            <div class='invitation-feedback'>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

Styleguide: ManageCollaborators
*/

@default-collaborator-input-height: 3.5em;

#kssref-managecollaborators {
  .kss-modifier__example {
    height: 500px;

    #modal {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      #modal-container,
      #modal-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}

#modal {
  #modal-box.modal-box--manage-collaborators {
    text-align: left;
    max-width: 60em;
    min-width: 40em;

    .title {
      font-size: @font-size-xl;
      font-weight: bold;
    }
    .message {
      margin-bottom: 1em;
    }

    .body {
      padding: @modal-padding;
      .send-invites {
        width: 100%;

        .collaborator-role-selector__current-role--manage {
          border: none;
          border-top-right-radius: @input-radius-border;

          &:hover {
            border: none;
          }
        }

        .collaborator-role-selector__current-role--manage,
        .menu__item--manage {
          height: 100%;
          padding: 0.38em;
        }

        button {
          height: @default-collaborator-input-height;
          padding: 1em 0.5em;
        }
      }

      .invitation-feedback {
        clear: left;
        margin-top: 0.5rem;
        margin-bottom: 0.5em;
      }
    }
  }
}

.menu--within-manage-collaborators {
  z-index: 99999;
  width: 100%;
  .menu__item {
    width: 100%;

    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 36px;
    height: 36px;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: normal;
    padding-left: 0;
    padding-right: 1.5rem;
  }
}

.form__group--flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.add-collaborator__container {
  align-items: center;
  border-radius: @input-radius-border;
  border: 1px solid @input-color-border;
  display: grid;
  width: 100%;
  margin-right: 1em;
  grid-template-columns: auto 124px;
  min-height: @default-collaborator-input-height;
  padding: 1px;
}

.add-collaborator__container--focus {
  border: 2px solid @color-turquoise;
  padding: 0;
}

.invitation-email__container {
  padding: 0.5em 0.5em 0.5em 0.5em;
  flex-direction: row;
}

.invitation-email__list {
  display: flex;
  flex-wrap: wrap;
}

.invitation-email__pill {
  border: 1px solid @input-color-border;
  border-radius: @input-radius-border;
  display: grid;
  grid-template-columns: 20px auto;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
}

.invitation-email__pill-remove {
  text-align: center;
  cursor: pointer;

  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;

  &:hover {
    background-color: @color-primary;
    color: white;
  }
}

.invitation-email__pill-email {
  overflow: hidden;
  padding: 0 0.5em 0 0.25em;
  text-overflow: ellipsis;
}

.invitation-email__pill--invalid {
  color: @color-primary;
  border-color: @color-primary;
}

.invitation-email__input {
  font-size: 1rem;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.send-email {
  &:focus {
    outline: none;
  }
}
